import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import FarelanesLogo from "../../../assets/images/LOGO.png"
import FarelanesSilverLogo from "../../../assets/images/SILVER.png"
import FarelanesGoldLogo from "../../../assets/images/GOLD_LOGO.png"
import {useAuth0} from "@auth0/auth0-react";
import UserService from "../../../services/UserService";

export const MobileNavBarBrand = ({ handleClick }) => {

    const [mf, setMf] = useState("Default");

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    useEffect(() => {
        // 👇️ This only runs once
        console.log('useEffect ran');

        // 👇️ fetch data from remote API
        async function getUsers() {
            try {
                const token = await getAccessTokenSilently();
                UserService.mySubscription(token).then(
                    response => {
                        setMf(response.data)
                    },
                    error => {
                        console.log('API timeout or session error; Kill session and redirect to login' + error);
                        loginWithRedirect({appState: {returnTo: '/'}});
                    }
                );


            } catch (err) {

            }
        }

        getUsers();
    }, []); // 👈️ Empty dependencies array

  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
        {mf=='Silver' && (
            <NavLink to="/dashboard">
                <img
                    className="nav-bar__logo"
                    src={FarelanesSilverLogo}
                    alt="Farelanes logo"
                    width="125"
                    height="37"
                />
            </NavLink>
        )}

        {mf=='Gold' && (
            <NavLink to="/dashboard">
                <img
                    className="nav-bar__logo"
                    src={FarelanesGoldLogo}
                    alt="Farelanes logo"
                    width="125"
                    height="37"
                />
            </NavLink>
        )}

        {mf=='Default' && (
            <NavLink to="/dashboard">
                <img
                    className="nav-bar__logo"
                    src={FarelanesLogo}
                    alt="Farelanes logo"
                    width="125"
                    height="37"
                />
            </NavLink>
        )}
    </div>
  );
};
