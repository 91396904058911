import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {PageLayout} from "../components/page-layout";
import {useNavigate} from "react-router-dom";
import UserService from "../services/UserService";
import {PageLoader} from "./page-loader";

export const UserVerificationJS = (props) => {

    let navigate = useNavigate();

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    useEffect(() => {
        // 👇️ This only runs once
        console.log('useEffect ran');

        // 👇️ fetch data from remote API
        async function getUsers() {
            try {
                const token = await getAccessTokenSilently();
                UserService.verify(user, token).then(
                    response => {
                        if (response.data.subscriptionType=='Silver' || response.data.subscriptionType==='Gold') {
                            navigate("/dashboard",{state:{refresh:true}})
                        } else {
                            navigate("/settings",{state:{refresh:true}})
                        }
                    },
                    error => {
                        console.log('API timeout or session error; Kill session and redirect to login' + error);
                        loginWithRedirect({appState: {returnTo: '/'}});
                    }
                );



            } catch (err) {

            }
        }

        getUsers();
    }, []); // 👈️ Empty dependencies array

    return (
        <PageLayout>
            <PageLoader></PageLoader>
        </PageLayout>
    );
};

export default withAuthenticationRequired(UserVerificationJS, {
    onRedirecting: () => <Loading/>,
});
