import React from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush} from 'recharts';


export const Rmaps = (props) => {

    let data = [
        {
            name: '15 DAYS',
            uv: 1400,
            pv: 1400,
            amt: 2400,
        },
        {
            name: '7 DAYS',
            uv: 1795,
            pv: 1795,
            amt: 2210,
        },
        {
            name: '3 DAYS',
            uv: 1500,
            pv: 1500,
            amt: 2290,
        },
        {
            name: 'YESTERDAY',
            uv: 1965,
            pv: 1965,
            amt: 2000,
        }
    ];

  return (
      <ResponsiveContainer className="rates-chart" width="100%" height={190}>
          <LineChart
              width={500}
              height={276}
              data={props.ticker.dataMaps.mapsData}
              syncId="anyId"
              margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
              }}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="pv" stroke="#82ca9d" fill="#82ca9d" />
              {/*<Brush />*/}
          </LineChart>
      </ResponsiveContainer>

  );
};

export default withAuthenticationRequired(Rmaps, {
  onRedirecting: () => <Loading />,
});
