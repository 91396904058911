import React, {useEffect} from "react";
import { Auth0Feature } from "./auth0-feature";

import FarelanesBackground from "../assets/images/farelanes-splash.jpg";
import SILVERTOP from "../assets/images/pricing/farelanes-silver-sales-top.png";
import GOLDTOP from "../assets/images/pricing/farelanes-gold-sales-top.png";
import PLATINUMTOP from "../assets/images/pricing/farelanes-platinum-sales-top.png";
// import PLATINUMTOP from "../assets/images/pricing/pricing-details.png";

import {Button} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {SignupButton} from "./buttons/signup-button";
import {LoginButton} from "./buttons/login-button";
import {SignupButtonOne} from "./buttons/signup-button-one";
import {NavBarBrand} from "./navigation/desktop/nav-bar-brand";
import {NavBarTabs} from "./navigation/desktop/nav-bar-tabs";
import {NavBarButtons} from "./navigation/desktop/nav-bar-buttons";
import UserService from "../services/UserService";
import { Parser } from 'html-to-react'
import {useNavigate} from "react-router-dom";

export const Auth0Features = () => {

  let navigate = useNavigate();


  const rawHTML = "<!doctype html>\n" +
      "<html>\n" +
      "<head>\n" +
      "<meta charset=\"UTF-8\">\n" +
      "<title>Untitled Document</title>\n" +
      "<style type=\"text/css\">\n" +
      "<!--\n" +
      ".excel1 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Aptos Narrow\", sans-serif;\n" +
      "text-align:general;\n" +
      "vertical-align:bottom;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel2 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Aptos Narrow\";\n" +
      "text-align:general;\n" +
      "vertical-align:bottom;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel12 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Aptos Narrow\", sans-serif;\n" +
      "text-align:center;\n" +
      "vertical-align:bottom;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "}\n" +
      ".excel13 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Aptos Narrow\";\n" +
      "text-align:center;\n" +
      "vertical-align:bottom;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "}\n" +
      ".excel15 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:#C1F0C8;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Aptos Narrow\";\n" +
      "text-align:center;\n" +
      "vertical-align:bottom;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "background:#3C7D22;\n" +
      "}\n" +
      ".excel14 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Aptos Narrow\";\n" +
      "text-align:center;\n" +
      "vertical-align:bottom;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel8 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:13.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:general;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel3 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "}\n" +
      ".excel16 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:#D9D9D9;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:1.0pt solid #D9D9D9;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:1.0pt solid #D9D9D9;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "background:#A6A6A6;\n" +
      "}\n" +
      ".excel9 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel17 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:#D9D9D9;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Helvetica, sans-serif;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:1.0pt solid #D9D9D9;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:1.0pt solid #D9D9D9;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "background:#A6A6A6;\n" +
      "}\n" +
      ".excel4 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "}\n" +
      ".excel18 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:#D9D9D9;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:1.0pt solid #D9D9D9;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:1.0pt solid #D9D9D9;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "background:#A6A6A6;\n" +
      "}\n" +
      ".excel5 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:12.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "}\n" +
      ".excel10 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:25.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel6 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:250pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:none;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "}\n" +
      ".excel19 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:#D9D9D9;\n" +
      "font-size:25.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:1.0pt solid #D9D9D9;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:1.0pt solid #D9D9D9;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "background:#A6A6A6;\n" +
      "}\n" +
      ".excel11 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:black;\n" +
      "font-size:13.0pt;\n" +
      "font-weight:700;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:\"Helvetica Neue\", sans-serif;\n" +
      "text-align:general;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "}\n" +
      ".excel20 {\n" +
      "padding-top:1px;\n" +
      "padding-right:1px;\n" +
      "padding-left:1px;\n" +
      "color:#D9D9D9;\n" +
      "font-size:25.0pt;\n" +
      "font-weight:400;\n" +
      "font-style:normal;\n" +
      "text-decoration:none;\n" +
      "font-family:Overpass;\n" +
      "text-align:center;\n" +
      "vertical-align:middle;\n" +
      "border:none;\n" +
      "white-space:nowrap;\n" +
      "border-top:1.0pt solid #D9D9D9;\n" +
      "border-right:1.0pt solid windowtext;\n" +
      "border-bottom:none;\n" +
      "border-left:1.0pt solid windowtext;\n" +
      "background:#A6A6A6;\n" +
      "}\n" +
      "-->\n" +
      "</style>\n" +
      "</head>\n" +
      "\n" +
      "<body>\n" +
      "<table cellspacing=\"0\" cellpadding=\"0\" class=\"excel1\">\n" +
      "                  <col width=\"291\" style=\"width:100%;\">\n" +
      "                  <col width=\"128\" span=\"2\" style=\"width:96pt;\">\n" +
      "                  <col width=\"128\" style=\"width:96pt;\">\n" +
      "                  <col width=\"128\" style=\"width:96pt;\">\n" +
      "                  <tr style=\"height:17.0pt;\">\n" +
      "                    <td class=\"excel2\" width=\"291\" style=\"height:17.0pt;width:218pt;font-size:12.0pt;color:white;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Aptos Narrow', sans-serif;border-top:1.0pt solid windowtext;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#4EA72E;\">FEATURE</td>\n" +
      "                    <td class=\"excel12\" width=\"128\" style=\"width:96pt;font-size:12.0pt;color:white;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Aptos Narrow', sans-serif;border-top:1.0pt solid windowtext;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#4EA72E;\">SILVER</td>\n" +
      "                    <td class=\"excel13\" width=\"128\" style=\"border-left:1.0pt solid windowtext;width:96pt;font-size:12.0pt;color:white;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Aptos Narrow', sans-serif;border-top:1.0pt solid windowtext;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#4EA72E;\">GOLD</td>\n" +
      "                    <td class=\"excel15\" width=\"128\" style=\"border-left:1.0pt solid windowtext;width:96pt;font-size:12.0pt;color:#C1F0C8;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Aptos Narrow', sans-serif;border-top:1.0pt solid windowtext;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#3C7D22;\">PLATINUM</td>\n" +
      "                    <td class=\"excel14\" width=\"128\" style=\"width:96pt;font-size:12.0pt;color:white;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Aptos Narrow', sans-serif;border-top:1.0pt solid windowtext;border-right:1.0pt solid windowtext;border-bottom:5pt solid #8ED973;border-left:none;background:#4EA72E;\">BDP</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:20.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:20.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">PRICE/MONTH</td>\n" +
      "                    <td class=\"excel3\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">$49.95 </td>\n" +
      "                    <td class=\"excel3\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">$99.95 </td>\n" +
      "                    <td class=\"excel16\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:1.0pt solid #D9D9D9;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">$199.95 </td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">Call</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:20.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:20.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">PRICE/YEAR</td>\n" +
      "                    <td class=\"excel3\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">$499.95 </td>\n" +
      "                    <td class=\"excel3\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">$999.95 </td>\n" +
      "                    <td class=\"excel17\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Helvetica, sans-serif;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">&nbsp;</td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">Call</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:20.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:20.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Search: City/Pairs Line Haul   Rates</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">unlimited</td>\n" +
      "                    <td class=\"excel4\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">unlimited</td>\n" +
      "                    <td class=\"excel18\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">unlimited</td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">unlimited</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:20.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:20.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Trailer   Types</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:10pt solid windowtext;\">37</td>\n" +
      "                    <td class=\"excel4\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">37</td>\n" +
      "                    <td class=\"excel18\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">37</td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">37</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:20.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:20.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Favorite Lanes</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">3</td>\n" +
      "                    <td class=\"excel4\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">3</td>\n" +
      "                    <td class=\"excel18\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">Unlimited</td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">Unlimited</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:20.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:20.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">City/Pair   Rate Tickers</td>\n" +
      "                    <td class=\"excel5\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">2-3</td>\n" +
      "                    <td class=\"excel5\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">2-3</td>\n" +
      "                    <td class=\"excel18\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">Unlimited</td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">Unlimited</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Top Paying Lanes</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Always Avail.</td>\n" +
      "                    <td class=\"excel4\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">Always Avail.</td>\n" +
      "                    <td class=\"excel18\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">Always   Avail.</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">X</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Favorite   Lane Hinting</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">N/A</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">City/Pair Level II Detail</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">N/A</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Export   Level II Detail</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">N/A</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Automated RFPs</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel9\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">N/A</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:35.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:35.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Charts/Graphs</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">N/A</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:35.0pt;\">\n" +
      "                    <td class=\"excel8\" style=\"height:35.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Programmable Dashboard</td>\n" +
      "                    <td class=\"excel4\" style=\"font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">N/A</td>\n" +
      "                    <td class=\"excel4\" style=\"border-left:1.0pt solid windowtext;font-size:12.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">coming soon</td>\n" +
      "                    <td class=\"excel18\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:12.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">coming   soon</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:10pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">X</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Lane   Rates</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Pick Date</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:10pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Drop   Date</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Drop Zip</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Lead   Time</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Fuel Surcharge</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:10pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Other   Fees</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">No. of Stops</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">Miles</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:37.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:37.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:.5pt solid #8ED973;border-left:1.0pt solid windowtext;background:#DAF2D0;\">Custom Charts</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:10pt solid windowtext;background:#DAF2D0;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;background:#DAF2D0;\">√</td>\n" +
      "                    <td class=\"excel19\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid #D9D9D9;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:.5pt solid #8ED973;border-left:none;background:#DAF2D0;\">√</td>\n" +
      "  </tr>\n" +
      "                  <tr style=\"height:36.0pt;\">\n" +
      "                    <td class=\"excel11\" style=\"height:36.0pt;font-size:13.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:'Helvetica Neue', sans-serif;border-top:.5pt solid #8ED973;border-right:none;border-bottom:1.0pt solid windowtext;border-left:1.0pt solid windowtext;\">Export   Data</td>\n" +
      "                    <td class=\"excel6\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid windowtext;border-left:1.0pt solid windowtext;\">X</td>\n" +
      "                    <td class=\"excel6\" style=\"border-left:1.0pt solid windowtext;font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid windowtext;\">√</td>\n" +
      "                    <td class=\"excel20\" style=\"border-top:1.0pt solid #D9D9D9;border-left:1.0pt solid windowtext;font-size:25.0pt;color:#D9D9D9;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid windowtext;background:#A6A6A6;\">√</td>\n" +
      "                    <td class=\"excel10\" style=\"font-size:25.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:none;text-line-through:none;font-family:Overpass;border-top:.5pt solid #8ED973;border-right:1.0pt solid windowtext;border-bottom:1.0pt solid windowtext;border-left:none;\">√</td>\n" +
      "  </tr>\n" +
      "</table>\n" +
      "</body>\n" +
      "</html>\n"

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();


  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/user-verify",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  useEffect(() => {
    // 👇️ This only runs once

    if (isAuthenticated) {
      navigate("/dashboard",{state:{refresh:true}});
    }
    loginWithRedirect({state:{refresh:true}});

  }, []); // 👈️ Empty dependencies array


  return (
      // <div className="auth0-features">
      //   <div className="form-group ">
      //     <div>
      //
      //
      //       <div>
      //         <section id="pricing" className="pricing-content section-padding">
      //           <div className="container">
      //             <div className="section-title text-center">
      //             </div>
      //             <div className="row text-center">
      //               <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp "
      //                    data-wow-duration="1s"
      //                    data-wow-delay="0.1s" data-wow-offset="0">
      //                 <div className="pricing_design">
      //                   <div className="single-pricing">
      //                     <div className="price-head">
      //                       {/*<h2>Silver</h2>*/}
      //                       <img className="silver-top" src={SILVERTOP}/>
      //                       <div><h1>$49.95</h1><span className="subscription-per-month">per month</span>
      //                         <p className="two-months-free lef-align-line-height">or get
      //                           two months FREE</p>
      //                         <p className="two-months-free lef-align-line-height">when
      //                           you pay $499.95/year!</p>
      //                       </div>
      //                     </div>
      //                     <p className="left-align-text">Search</p>
      //                     <p className="left-align-text-after lef-align-line-height">City Pair
      //                       Line Haul Rates</p>
      //                     <p className="left-align-text-after lef-align-line-height">All
      //                       Trailer Types & Power
      //                       Only</p>
      //
      //                     <p className="left-align-text">Personalize</p>
      //                     <p className="left-align-text-after lef-align-line-height">Favorite
      //                       Lanes</p>
      //                     <p className="left-align-text-after lef-align-line-height">City Pair
      //                       Rate Tickers</p>
      //
      //
      //                     <p className="left-align-text">Top Paying <span><small>Lanes always available</small></span>
      //                     </p>
      //                     <br/><br/><br/>
      //
      //
      //                     <div>
      //                     </div>
      //
      //                     <br/>
      //                     <br/>
      //                     <br/>
      //
      //
      //                   </div>
      //
      //                 </div>
      //
      //               </div>
      //               <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp  "
      //                    data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
      //                 <div className="pricing_design">
      //                   <div className="single-pricing">
      //                     <div className="price-head">
      //                       <div className="price-head">
      //                         {/*<h2>Silver</h2>*/}
      //                         <img className="silver-top" src={GOLDTOP}/>
      //                         <div><h1>$99.95</h1><span
      //                             className="subscription-per-month">per month</span>
      //
      //                           <p className="two-months-free lef-align-line-height">or
      //                             get two months FREE</p>
      //                           <p className="two-months-free lef-align-line-height">when
      //                             you pay $999.95/year!</p>
      //                         </div>
      //                       </div>
      //                     </div>
      //                     <p className="left-align-text">All Silver Features Plus</p>
      //                     <p className="left-align-text">Hinting in your Favorite Lanes</p>
      //                     <p className="left-align-text-after"> Tool Tip hinting reveals
      //                       assessorial details</p>
      //
      //
      //                     <p className="left-align-text">City Pair Loading with level 2
      //                       detail</p>
      //                     <p className="left-align-text-after">Level 2 load optics enable
      //                       (pick / drop zone, load analysis, lane rates vs.
      //                       linehaul, Fuel surcharge, all assessorial
      //                       fees optics by load)
      //                     </p>
      //                     <p className="left-align-text-after">Export level 2 data for
      //                       additional analysis
      //                       and reporting
      //                     </p>
      //
      //
      //                     <p className="left-align-text">Top Paying <span><small>Lanes always available</small></span>
      //                     </p>
      //
      //
      //                     <div className="pricing-price">
      //
      //
      //                     </div>
      //                     <br/><br/><br/><br/><br/><br/><br/>
      //
      //                     {/*<a href="#" className="price_btn disabled-link">Order Now</a>*/}
      //                   </div>
      //                 </div>
      //               </div>
      //               <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp  "
      //                    data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
      //                 <div className="pricing_design">
      //                   <div className="single-pricing ">
      //
      //                     {/*<div className="overlay">*/}
      //                     {/*    */}
      //                     {/*</div>*/}
      //                     <div className="price-head ">
      //                       <div className="price-head">
      //                         {/*<h2>Silver</h2>*/}
      //                         <img className="silver-top" src={PLATINUMTOP}/>
      //                         <div><h1>$149.95</h1><span
      //                             className="subscription-per-month">per month</span>
      //
      //                           <p className="two-months-free lef-align-line-height">or
      //                             get two months FREE</p>
      //                           <p className="two-months-free lef-align-line-height">when
      //                             you pay $1499.95/year!</p>
      //                         </div>
      //                       </div>
      //
      //                       <hr/>
      //                     </div>
      //                     <div className="special-card">
      //                       <b>Coming soon! See https://farelanes.com for details or
      //                         contact@farelanes.com.</b>
      //                       <br/>
      //                       <br/>
      //                       <br/>
      //                       <h2>COMING SOON</h2>
      //
      //
      //                     </div>
      //
      //                     <div className="pricing-price">
      //
      //
      //                       {/*<p className="subscriptions-coming-soon">COMING SOON</p>*/}
      //
      //                     </div>
      //                     <br/><br/><br/><br/><br/><br/><br/>
      //                     {/*<a href="#" className="price_btn disabled-link">Order Now</a>*/}
      //
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //             <br/>
      //             <div className="kapil">
      //
      //               {Parser().parse(rawHTML)}
      //             </div>
      //           </div>
      //         </section>
      //       </div>
      //
      //
      //       <div>
      //
      //
      //       </div>
      //
      //     </div>
      //   </div>
      // </div>
      <div></div>
  );
};
