import React, {Fragment, useEffect, useMemo, useState} from "react";
import 'react-tabs/style/react-tabs.css';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {PageLoader} from "../components/page-loader";
import DataTable from "react-data-table-component";
import AsyncSelect from "react-select/async";
import LaneService from "../services/LaneService";
import {v4 as uuidv4} from 'uuid';
import Select from "react-select";
import {Table} from "react-bootstrap";
import {token} from "morgan";
import UserService from "../services/UserService";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {abbrRegion, customStyles, daysStyles, equipmentTypeStyles, radiusStyles} from "./Constants"; // Import css

export const Favorites = () => {

    const [dashReady, setDashReady] = useState(false);
    const options = {
        title: 'Title',
        message: 'Message',
        buttons: [
            {
                label: 'Yes',
                onClick: () => alert('Click Yes')
            },
            {
                label: 'No',
                onClick: () => alert('Click No')
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {
        },
        afterClose: () => {
        },
        onClickOutside: () => {
        },
        onKeypress: () => {
        },
        onKeypressEscape: () => {
        },
        overlayClassName: "overlay-custom-class-name"
    };

    const equipmentOptions = [
        {value: 'Auto Carrier', label: 'Auto Carrier'},
        {value: 'Container', label: 'Container'},
        {value: 'Conestoga', label: 'Conestoga'},
        {value: 'Dry Van', label: 'Dry Van'},
        {value: 'Flatbed', label: 'Flatbed'},
        {value: 'Hopper Bottom', label: 'Hopper Bottom'},
        {value: 'Lowboy', label: 'Lowboy'},
        {value: 'Double Drop', label: 'Double Drop'},
        {value: 'Maxi', label: 'Maxi'},
        {value: 'Power Only', label: 'Power Only'},
        {value: 'Pneumatic', label: 'Pneumatic'},
        {value: 'Reefer', label: 'Reefer'},
        {value: 'Straight Box', label: 'Straight Box'},
        {value: 'Step Deck', label: 'Step Deck'},
        {value: 'Sprinter', label: 'Sprinter'},
        {value: 'Tanker', label: 'Tanker'},
        {value: 'Truck and Trailer', label: 'Truck & Trailer'},
    ];

    const radiusOptions = [
        {value: 50, label: '50 mi'},
        {value: 100, label: '100 mi'},
        {value: 150, label: '150 mi'},
        {value: 200, label: '200 mi'}
    ];

    const dayRangeOptions = [
        {value: 120, label: '120'},
        {value: 60, label: '60'},
        {value: 30, label: '30'},
        {value: 7, label: '7'},
    ];

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const [fistTime, setFistTime] = useState(true);
    const [inputPickCityValue, setPickCityValue] = useState([]);
    const [selectedPickCityValue, setSelectedPickCityValue] = useState([]);

    const [inputDropCityValue, setDropCityValue] = useState([]);
    const [selectedDropCityValue, setSelectedDropCityValue] = useState([]);

    const [radiusValue, setRadiusValue] = useState([{value: 200, label: '200 mi'}]);
    const [dropRadiusValue, setDropRadiusValue] = useState([{value: 200, label: '200 mi'}]);
    const [equipmentArray, setEquipmentArray] = useState([]);


    const handlePickCityRadiusChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_pick_city_radius = event;
        setEquipmentArray[row_id] = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };

    const handleDropRadiusChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_drop_city_radius = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };


    const [data, setData] = useState([]);


    // load options using API call
    const loadOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getCityOptions(inputValue, token).then(
            response => {
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    };


    const someArray = [];


    // handle input change event ONE
    const handleEquipmentChange = (event, row_id) => {
        console.log('Row_id', row_id);
        console.log('Event', event);
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_equipment_type = event;
        console.log('Data', data);
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };

    const [dayRangeValue, setDayRangeValue] = useState([]);

    const handleDayRangeValueChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_number_of_days = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };

    const handlePickCityInputChange = (value, row_id) => {
        console.log('===================================1' + value);
        // let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        // data[rowIndex].request_pick_city = value;
        // setSelectedPickCityValue[row_id] = (value);
    };

    // handle selection
    const handlePickCityChange = (value, row_id) => {
        console.log('Value', value);
        console.log('Data', data);
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_pick_city = value;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    }

    const handleDropCityInputChange = (value, row_id) => {
        setDropCityValue[row_id] = value;
    };

    // handle selection
    const handleDropCityChange = (value, row_id) => {
        console.log('=======================================***');
        console.log(value);
        console.log(row_id)
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_drop_city = value;
        setSelectedDropCityValue[row_id] = (value);

    }


    function clickHandler(id) {
        setData(data.concat({row_id: uuidv4(), id: null}))
    }


    function submit(event) {
        let rowIndex = data.findIndex(item => item['row_id'] === event);
        let message = "Confirm delete ?"
        if (data[rowIndex].request_pick_city_radius !== undefined && data[rowIndex].request_drop_city !== null) {
            let pickState = data[rowIndex].request_pick_city.stateName;
            let pickCity = data[rowIndex].request_pick_city.city;
            let dropCity = data[rowIndex].request_drop_city.city;
            let dropState = data[rowIndex].request_drop_city.stateName;
            message = 'Delete lane pair ' + pickCity + ', ' + pickState + ' to ' + dropCity + ', ' + dropState;
        }

        confirmAlert({
            title: 'Delete Lane',
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setData(data.filter(item => item.row_id !== event))
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        console.log('Canceling action. Do nothing')
                    }
                }
            ]
        });
    };


    function deleteHandler(event) {
        setData(data.filter(item => item.row_id !== event))
    }

    async function laneSearch(row_id) {
        setDashReady(false);
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        console.log(data[rowIndex].request_pick_city)
        const token = await getAccessTokenSilently();
        LaneService.getLaneStatistics(data[rowIndex].request_pick_city.city,
            data[rowIndex].request_pick_city.stateName,
            data[rowIndex].request_drop_city.city,
            data[rowIndex].request_drop_city.stateName,
            data[rowIndex].request_equipment_type.value,
            data[rowIndex].request_pick_city_radius.value,
            data[rowIndex].request_drop_city_radius.value,
            data[rowIndex].request_number_of_days.value,
            token).then(
            response => {
                console.log('Async() trigger ....');
                console.log(response.data.thirtyDaysAverage.costPerMile);
                console.log(response.data.thirtyDaysAverage.averageLoadRate);
                // data.find(x => x.row_id == row_id).raterPerMile=response.data.thirtyDaysAverage.costPerMile;
                const updatedData = data.map(x => (x.row_id === row_id ? {
                    ...x,
                    ratePerMile: response.data.thirtyDaysAverage.costPerMile,
                    ratePerLoad: response.data.thirtyDaysAverage.averageLoadRate
                } : x));
                setData(updatedData);
                setDashReady(true);
                //The save() needs to happen here and needs to be in SYNC ....with data
                LaneService.syncDashBoard(updatedData, token).then(
                    response => {
                        console.log(response);
                        setData(response.data.filter(item => item.row_id !== 'mutate_data'));
                    }
                )

            },
            error => {
                if (error.response && error.response.status === 401) {
                    // AuthService.logout();
                    // window.location.replace('/login');
                    // EventBus.dispatch("logout");
                }
            }
        );
    }


    async function favorite(row_id) {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        const token = await getAccessTokenSilently();
        let favoriteValue = data[rowIndex].favorite;
        console.log('Fav value', favoriteValue);
        if (null == favoriteValue || undefined == favoriteValue) {
            data[rowIndex].favorite = true;
        } else {
            data[rowIndex].favorite = !data[rowIndex].favorite;
        }
        setData(data.filter(item => item.row_id !== 'mutate_data'));
        LaneService.syncDashBoard(data, token).then(
            response => {
                setData(response.data.filter(item => item.row_id !== 'mutate_data'));
            }
        )
    }


    const callApi = async () => {
        setDashReady(false);
        if (fistTime) {
            const token = await getAccessTokenSilently();
            LaneService.loadDashBoardFavorites(token).then(
                response => {
                    setFistTime(false);
                    setData(response);
                }
            );
        } else {
            setDashReady(true);
        }
    };


    React.useEffect(() => {
        callApi().then(r => console.log('finish refresh'));
    }, [data]);


    return (

        <div>
            {!dashReady && (<PageLoader></PageLoader>)}
            {dashReady && (
                <div className="container-fluid dashboard-text">

                    <Table>
                        <thead className="dashboard-header">
                        <tr>
                            <th>Equipment Type</th>
                            <th>Pick City or Zip</th>
                            <th>Radius/miles</th>
                            <th>Drop City or Zip</th>
                            <th>Radius/miles</th>
                            <th>Days</th>
                            <th>$/mile</th>
                            <th>$/load</th>
                            <th>Refresh</th>
                        </tr>
                        </thead>
                        <tbody>

                        {data.map(dash_item => (
                            <tr key={dash_item.row_id}>
                                <td>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        styles={equipmentTypeStyles}
                                        value={dash_item.request_equipment_type}
                                        // onChange={handleEquipmentChange}
                                        onChange={event => handleEquipmentChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td className="asyncselect">
                                    <AsyncSelect
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        cacheOptions
                                        styles={customStyles}
                                        defaultOptions
                                        value={dash_item.request_pick_city}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={event => handlePickCityInputChange(event, dash_item.row_id)}
                                        onChange={event => handlePickCityChange(event, dash_item.row_id)}
                                    />
                                </td>

                                <td>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        styles={radiusStyles}
                                        value={dash_item.request_pick_city_radius}
                                        onChange={event => handlePickCityRadiusChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td className="asyncselect">
                                    <AsyncSelect
                                        cacheOptions
                                        styles={customStyles}
                                        defaultOptions
                                        value={dash_item.request_drop_city}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        // onInputChange={handleDropCityInputChange(dash_item.row_id)}
                                        onChange={event => handleDropCityChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td className="asyncselect">
                                    <Select
                                        label="What radius"
                                        styles={radiusStyles}
                                        options={radiusOptions}
                                        value={dash_item.request_drop_city_radius}
                                        onChange={event => handleDropRadiusChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td>
                                    <Select
                                        label="What day"
                                        styles={daysStyles}
                                        options={dayRangeOptions}
                                        value={dash_item.request_number_of_days}
                                        onChange={event => handleDayRangeValueChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td>
                                    {dash_item.ratePerMile && <span
                                        className="dashboard-dollar-prices">${(Math.round(dash_item.ratePerMile * 100) / 100).toFixed(2)}</span>}
                                </td>
                                <td>
                                    {dash_item.ratePerLoad && <span
                                        className="dashboard-dollar-prices">${(Math.round(dash_item.ratePerLoad * 100) / 100).toFixed(0)}</span>}
                                </td>
                                <td className="heart-button">
                                    <button onClick={(e) => {
                                        laneSearch(dash_item.row_id).then(console.log('k'));
                                    }} id={dash_item.row_id}><i className="bi bi-arrow-repeat"></i></button>
                                </td>
                            </tr>))}
                        <tr>
                        </tr>
                        </tbody>
                    </Table>
                    <br/><br/><br/><br/>
                </div>)}
        </div>
    );
};

export default withAuthenticationRequired(Favorites, {
    onRedirecting: () => <PageLoader/>,
});
