import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
// import Loading from "../components/Loading";
// import LaneService, {getTopLanes} from "../services/LaneService";
import DataTable from 'react-data-table-component';
import LaneService from "../services/LaneService";
import {formatValue} from "react-currency-input-field";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {PageLoader} from "./page-loader";
import {initAmplitude, sendAmplitudeData, setAmplitudeUserId} from "../common/amplitude";

export const TopLanes = () => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
        subscription: false,
        subscriptionType: 'Default'
    });

    const {
        user,
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    initAmplitude();

    setAmplitudeUserId(user.email);

    // sendAmplitudeData('Clicked Homepage Button', {'finished_flow': false, 'clicks': 15});


    sendAmplitudeData("Top Lanes", {

    });

    const customStyles = {
        rows: {
            style: {
                minHeight: '15px;', // override the row height
                fontSize: '15px',
                height: '35px',
            }
        },

        headCells: {
            style: {
                fontFamily: "Overpass-SemiBold",
                textDecoration:'underline',
                fontSize: '14px',
                height: '25px',
                fontWeight: 'bold',
                justifyContent: 'left',
            },
        },

        // cells: {
        //     style: {
        //         paddingLeft: '8px', // override the cell padding for data cells
        //         paddingRight: '8px',
        //     },
        // },
    };


    // const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    // const ExpandedComponent = ({ data }) => <pre>{data.queryCurrentStatistic && data.queryCurrentStatistic.costPerMile}</pre>;
    const ExpandedComponent = ({data}) => <div>
        <div className="container-fluid">
        <div className="row">
            <div className="col">
                <div className="lookup-card">
                    <div className="card-body">
                        {data.laneStatistics != undefined && data.laneStatistics.thirtyDaysAverage != undefined &&

                            <div className={
                                data.laneStatistics != undefined && data.laneStatistics.thirtyDaysAverage != undefined && data.laneStatistics.thirtyDaysAverage.costPerMile.toString() == '0'
                                    ? "special-card"
                                    : "border-dark"
                            }
                            >
                                {state.subscriptionType == 'Gold' &&
                                <ReactTooltip id="thirtydays" multiline={true}/>
                                }
                                <a data-tooltip-id="thirtydays" data-tooltip-html={
                                    'No.of.stops - low ' + data.laneStatistics.thirtyDaysAverage.lowNumberOfStops + ' high ' + data.laneStatistics.thirtyDaysAverage.highNumberOFStops + '<br/>' +
                                    'Lead time - low' + data.laneStatistics.thirtyDaysAverage.lowLeadTime + ' high ' + data.laneStatistics.thirtyDaysAverage.highLeadTime + '<br/>' +
                                    'Fuel surcharge - low' + data.laneStatistics.thirtyDaysAverage.lowFuelSurcharge + ' high ' + data.laneStatistics.thirtyDaysAverage.highFuelSurcharge + '<br/>' +
                                    'Lumper - low' + data.laneStatistics.thirtyDaysAverage.lowLumper + ' high ' + data.laneStatistics.thirtyDaysAverage.highLumper + '<br/>' +
                                    'Pallet charge - low' + data.laneStatistics.thirtyDaysAverage.lowPalletCharge + ' high ' + data.laneStatistics.thirtyDaysAverage.highPalletCharge + '<br/>' +
                                    'Weight ticket - low' + data.laneStatistics.thirtyDaysAverage.lowWeightTicket + ' high ' + data.laneStatistics.thirtyDaysAverage.highWeightTicket + '<br/>' +
                                    'Interchange - low' + data.laneStatistics.thirtyDaysAverage.lowInterchange + ' high ' + data.laneStatistics.thirtyDaysAverage.highInterchange + '<br/>' +
                                    'Container fee - low' + data.laneStatistics.thirtyDaysAverage.lowContainerFee + ' high ' + data.laneStatistics.thirtyDaysAverage.highContainerFee + '<br/>' +
                                    'Truck order - low' + data.laneStatistics.thirtyDaysAverage.lowTruckOrder + ' high ' + data.laneStatistics.thirtyDaysAverage.highTruckOrder + '<br/>' +
                                    'Unloading Fees - low' + data.laneStatistics.thirtyDaysAverage.lowUnloadingFees + ' high ' + data.laneStatistics.thirtyDaysAverage.highUnloadingFees + '<br/>' +
                                    'Detention - low' + data.laneStatistics.thirtyDaysAverage.lowDetention + ' high ' + data.laneStatistics.thirtyDaysAverage.highDetention + '<br/>'
                                }>

                                    <h6>30 days</h6>

                                    <p>{formatValue({
                                        value: data.laneStatistics.thirtyDaysAverage.costPerMile.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 2,
                                        suffix: '/mile'
                                    })}</p>

                                    <p>{formatValue({
                                        value: data.laneStatistics.thirtyDaysAverage.averageLoadRate.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 0,
                                        suffix: '/load'
                                    })}</p>
                                </a>
                                <hr/>
                                {/*<small>Average Load*/}
                                {/*    Miles: {financial(data.laneStatistics.oneDaysAverage.averageLoadMiles)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req pick*/}
                                {/*    up: {financial(data.laneStatistics.oneDaysAverage.averageDistanceFromRequestedPickup)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req drop*/}
                                {/*    off: {financial(data.laneStatistics.oneDaysAverage.averageDistanceFromRequestedDrop)} miles</small><br/>*/}

                            </div>}

                    </div>
                </div>
            </div>
            <div className="col">
                <div className="lookup-card">
                    <div className="card-body">
                        {data.laneStatistics != undefined && data.laneStatistics.fifteenDaysAverage != undefined &&
                            <div className={
                                data.laneStatistics != undefined && data.laneStatistics.fifteenDaysAverage != undefined && data.laneStatistics.fifteenDaysAverage.costPerMile.toString() == '0'
                                    ? "special-card"
                                    : "border-dark"
                            }
                            > {state.subscriptionType == 'Gold' &&
                                <ReactTooltip id="fifteenDaysAverage" multiline={true}/>
                            }
                                <a data-tooltip-id="fifteenDaysAverage" data-tooltip-html={
                                    'No.of.stops - low ' + data.laneStatistics.fifteenDaysAverage.lowNumberOfStops + ' high ' + data.laneStatistics.fifteenDaysAverage.highNumberOFStops + '<br/>' +
                                    'Lead time - low' + data.laneStatistics.fifteenDaysAverage.lowLeadTime + ' high ' + data.laneStatistics.fifteenDaysAverage.highLeadTime + '<br/>' +
                                    'Fuel surcharge - low' + data.laneStatistics.fifteenDaysAverage.lowFuelSurcharge + ' high ' + data.laneStatistics.fifteenDaysAverage.highFuelSurcharge + '<br/>' +
                                    'Lumper - low' + data.laneStatistics.fifteenDaysAverage.lowLumper + ' high ' + data.laneStatistics.fifteenDaysAverage.highLumper + '<br/>' +
                                    'Pallet charge - low' + data.laneStatistics.fifteenDaysAverage.lowPalletCharge + ' high ' + data.laneStatistics.fifteenDaysAverage.highPalletCharge + '<br/>' +
                                    'Weight ticket - low' + data.laneStatistics.fifteenDaysAverage.lowWeightTicket + ' high ' + data.laneStatistics.fifteenDaysAverage.highWeightTicket + '<br/>' +
                                    'Interchange - low' + data.laneStatistics.fifteenDaysAverage.lowInterchange + ' high ' + data.laneStatistics.fifteenDaysAverage.highInterchange + '<br/>' +
                                    'Container fee - low' + data.laneStatistics.fifteenDaysAverage.lowContainerFee + ' high ' + data.laneStatistics.fifteenDaysAverage.highContainerFee + '<br/>' +
                                    'Truck order - low' + data.laneStatistics.fifteenDaysAverage.lowTruckOrder + ' high ' + data.laneStatistics.fifteenDaysAverage.highTruckOrder + '<br/>' +
                                    'Unloading fees - low' + data.laneStatistics.fifteenDaysAverage.lowUnloadingFees + ' high ' + data.laneStatistics.fifteenDaysAverage.highUnloadingFees + '<br/>' +
                                    'Detention - low' + data.laneStatistics.fifteenDaysAverage.lowDetention + ' high ' + data.laneStatistics.fifteenDaysAverage.highDetention + '<br/>'
                                }>

                                    <h6>15 days</h6>


                                    <p>{formatValue({
                                        value: data.laneStatistics.fifteenDaysAverage.costPerMile.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 2,
                                        suffix: '/mile'
                                    })}</p>

                                    <p>{formatValue({
                                        value: data.laneStatistics.fifteenDaysAverage.averageLoadRate.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 0,
                                        suffix: '/load'
                                    })}
                                    </p>
                                </a>
                                <hr/>
                                {/*<small>Average Load*/}
                                {/*    Miles: {financial(data.laneStatistics.fifteenDaysAverage.averageLoadMiles)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req pick*/}
                                {/*    up: {financial(data.laneStatistics.fifteenDaysAverage.averageDistanceFromRequestedPickup)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req drop*/}
                                {/*    off: {financial(data.laneStatistics.fifteenDaysAverage.averageDistanceFromRequestedDrop)} miles</small><br/>*/}

                            </div>}

                    </div>
                </div>
            </div>
            <div className="col">
                <div className="lookup-card">
                    <div className="card-body">
                        {data.laneStatistics != undefined && data.laneStatistics.sevenDaysAverage != undefined &&

                            <div className={
                                data.laneStatistics != undefined && data.laneStatistics.sevenDaysAverage != undefined && data.laneStatistics.sevenDaysAverage.costPerMile.toString() == '0'
                                    ? "special-card"
                                    : "border-dark"
                            }
                            >
                                {state.subscriptionType == 'Gold' &&
                                <ReactTooltip id="sevenDaysAverage" multiline={true}/>
                                }
                                <a data-tooltip-id="sevenDaysAverage" data-tooltip-html={
                                    'No.of.stops - low ' + data.laneStatistics.sevenDaysAverage.lowNumberOfStops + ' high ' + data.laneStatistics.sevenDaysAverage.highNumberOFStops + '<br/>' +
                                    'Lead time - low' + data.laneStatistics.sevenDaysAverage.lowLeadTime + ' high ' + data.laneStatistics.sevenDaysAverage.highLeadTime + '<br/>' +
                                    'Fuel surcharge - low' + data.laneStatistics.sevenDaysAverage.lowFuelSurcharge + ' high ' + data.laneStatistics.sevenDaysAverage.highFuelSurcharge + '<br/>' +
                                    'Lumper - low' + data.laneStatistics.sevenDaysAverage.lowLumper + ' high ' + data.laneStatistics.sevenDaysAverage.highLumper + '<br/>' +
                                    'Pallet charge - low' + data.laneStatistics.sevenDaysAverage.lowPalletCharge + ' high ' + data.laneStatistics.sevenDaysAverage.highPalletCharge + '<br/>' +
                                    'Weight ticket - low' + data.laneStatistics.sevenDaysAverage.lowWeightTicket + ' high ' + data.laneStatistics.sevenDaysAverage.highWeightTicket + '<br/>' +
                                    'Interchange - low' + data.laneStatistics.sevenDaysAverage.lowInterchange + ' high ' + data.laneStatistics.sevenDaysAverage.highInterchange + '<br/>' +
                                    'Container fee - low' + data.laneStatistics.sevenDaysAverage.lowContainerFee + ' high ' + data.laneStatistics.sevenDaysAverage.highContainerFee + '<br/>' +
                                    'Truck order - low' + data.laneStatistics.sevenDaysAverage.lowTruckOrder + ' high ' + data.laneStatistics.sevenDaysAverage.highTruckOrder + '<br/>' +
                                    'Unloading Fees - low' + data.laneStatistics.sevenDaysAverage.lowUnloadingFees + ' high ' + data.laneStatistics.sevenDaysAverage.highUnloadingFees + '<br/>' +
                                    'Detention - low' + data.laneStatistics.sevenDaysAverage.lowDetention + ' high ' + data.laneStatistics.sevenDaysAverage.highDetention + '<br/>'
                                }>

                                    <h6>7 days</h6>

                                    <p>{formatValue({
                                        value: data.laneStatistics.sevenDaysAverage.costPerMile.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 2,
                                        suffix: '/mile'
                                    })}</p>

                                    <p>{formatValue({
                                        value: data.laneStatistics.sevenDaysAverage.averageLoadRate.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 0,
                                        suffix: '/load'
                                    })}</p>
                                </a>
                                <hr/>
                                {/*<small>Average Load*/}
                                {/*    Miles: {financial(data.laneStatistics.sevenDaysAverage.averageLoadMiles)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req pick*/}
                                {/*    up: {financial(data.laneStatistics.sevenDaysAverage.averageDistanceFromRequestedPickup)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req drop*/}
                                {/*    off: {financial(data.laneStatistics.sevenDaysAverage.averageDistanceFromRequestedDrop)} miles</small><br/>*/}


                            </div>}

                    </div>
                </div>
            </div>
            <div className="col">
                <div className="lookup-card">
                    <div className="card-body">
                        {data.laneStatistics != undefined && data.laneStatistics.threeDaysAverage != undefined &&

                            <div className={
                                data.laneStatistics != undefined && data.laneStatistics.threeDaysAverage != undefined && data.laneStatistics.threeDaysAverage.costPerMile.toString() == '0'
                                    ? "special-card"
                                    : "border-dark"
                            }
                            >
                                {state.subscriptionType == 'Gold' &&
                                <ReactTooltip id="threeDaysAverage" multiline={true}/>
                                }
                                <a data-tooltip-id="threeDaysAverage" data-tooltip-html={
                                    'No.of.stops - low ' + data.laneStatistics.threeDaysAverage.lowNumberOfStops + ' high ' + data.laneStatistics.threeDaysAverage.highNumberOFStops + '<br/>' +
                                    'Lead time - low' + data.laneStatistics.threeDaysAverage.lowLeadTime + ' high ' + data.laneStatistics.threeDaysAverage.highLeadTime + '<br/>' +
                                    'Fuel surcharge - low' + data.laneStatistics.threeDaysAverage.lowFuelSurcharge + ' high ' + data.laneStatistics.threeDaysAverage.highFuelSurcharge + '<br/>' +
                                    'Lumper - low' + data.laneStatistics.threeDaysAverage.lowLumper + ' high ' + data.laneStatistics.threeDaysAverage.highLumper + '<br/>' +
                                    'Pallet charge - low' + data.laneStatistics.threeDaysAverage.lowPalletCharge + ' high ' + data.laneStatistics.threeDaysAverage.highPalletCharge + '<br/>' +
                                    'Weight ticket - low' + data.laneStatistics.threeDaysAverage.lowWeightTicket + ' high ' + data.laneStatistics.threeDaysAverage.highWeightTicket + '<br/>' +
                                    'Interchange - low' + data.laneStatistics.threeDaysAverage.lowInterchange + ' high ' + data.laneStatistics.threeDaysAverage.highInterchange + '<br/>' +
                                    'Container fee - low' + data.laneStatistics.threeDaysAverage.lowContainerFee + ' high ' + data.laneStatistics.threeDaysAverage.highContainerFee + '<br/>' +
                                    'Truck order - low' + data.laneStatistics.threeDaysAverage.lowTruckOrder + ' high ' + data.laneStatistics.threeDaysAverage.highTruckOrder + '<br/>' +
                                    'Unloading Feed - low' + data.laneStatistics.threeDaysAverage.lowUnloadingFees + ' high ' + data.laneStatistics.threeDaysAverage.highUnloadingFees + '<br/>' +
                                    'Detention - low' + data.laneStatistics.threeDaysAverage.lowDetention + ' high ' + data.laneStatistics.threeDaysAverage.highDetention + '<br/>'
                                }>

                                    <h6>3 days</h6>

                                    <p>{formatValue({
                                        value: data.laneStatistics.threeDaysAverage.costPerMile.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 2,
                                        suffix: '/mile'
                                    })}</p>

                                    <p>{formatValue({
                                        value: data.laneStatistics.threeDaysAverage.averageLoadRate.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 0,
                                        suffix: '/load'
                                    })}</p>
                                </a>
                                <hr/>
                                {/*<small>Average Load*/}
                                {/*    Miles: {financial(data.laneStatistics.threeDaysAverage.averageLoadMiles)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req pick*/}
                                {/*    up: {financial(data.laneStatistics.threeDaysAverage.averageDistanceFromRequestedPickup)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req drop*/}
                                {/*    off: {financial(data.laneStatistics.threeDaysAverage.averageDistanceFromRequestedDrop)} miles</small><br/>*/}


                            </div>}

                    </div>
                </div>
            </div>

            <div className="col">
                <div className="lookup-card">
                    <div className="card-body">
                        {data.laneStatistics != undefined && data.laneStatistics.oneDaysAverage != undefined &&

                            <div className={
                                data.laneStatistics != undefined && data.laneStatistics.oneDaysAverage != undefined && data.laneStatistics.oneDaysAverage.costPerMile.toString() == '0'
                                    ? "special-card"
                                    : "border-dark"
                            }
                            >
                                {state.subscriptionType == 'Gold' &&
                                <ReactTooltip id="onedays" multiline={true}/>
                                }
                                <a data-tooltip-id="onedays" data-tooltip-html={
                                    'No.of.stops - low ' + data.laneStatistics.oneDaysAverage.lowNumberOfStops + ' high ' + data.laneStatistics.oneDaysAverage.highNumberOFStops + '<br/>' +
                                    'Lead time - low' + data.laneStatistics.oneDaysAverage.lowLeadTime + ' high ' + data.laneStatistics.oneDaysAverage.highLeadTime + '<br/>' +
                                    'Fuel surcharge - low' + data.laneStatistics.oneDaysAverage.lowFuelSurcharge + ' high ' + data.laneStatistics.oneDaysAverage.highFuelSurcharge + '<br/>' +
                                    'Lumper - low' + data.laneStatistics.oneDaysAverage.lowLumper + ' high ' + data.laneStatistics.oneDaysAverage.highLumper + '<br/>' +
                                    'Pallet charge - low' + data.laneStatistics.oneDaysAverage.lowPalletCharge + ' high ' + data.laneStatistics.oneDaysAverage.highPalletCharge + '<br/>' +
                                    'Weight ticket - low' + data.laneStatistics.oneDaysAverage.lowWeightTicket + ' high ' + data.laneStatistics.oneDaysAverage.highWeightTicket + '<br/>' +
                                    'Interchange - low' + data.laneStatistics.oneDaysAverage.lowInterchange + ' high ' + data.laneStatistics.oneDaysAverage.highInterchange + '<br/>' +
                                    'Container fee - low' + data.laneStatistics.oneDaysAverage.lowContainerFee + ' high ' + data.laneStatistics.oneDaysAverage.highContainerFee + '<br/>' +
                                    'Truck order - low' + data.laneStatistics.oneDaysAverage.lowTruckOrder + ' high ' + data.laneStatistics.oneDaysAverage.highTruckOrder + '<br/>' +
                                    'Unloading Fees - low' + data.laneStatistics.oneDaysAverage.lowUnloadingFees + ' high ' + data.laneStatistics.oneDaysAverage.highUnloadingFees + '<br/>' +
                                    'Detention - low' + data.laneStatistics.oneDaysAverage.lowDetention + ' high ' + data.laneStatistics.oneDaysAverage.highDetention + '<br/>'
                                }>

                                    <h6>Yesterday</h6>

                                    <p>{formatValue({
                                        value: data.laneStatistics.oneDaysAverage.costPerMile.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 2,
                                        suffix: '/mile'
                                    })}</p>

                                    <p>{formatValue({
                                        value: data.laneStatistics.oneDaysAverage.averageLoadRate.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 0,
                                        suffix: '/load'
                                    })}</p>
                                </a>
                                <hr/>
                                {/*<small>Average Load*/}
                                {/*    Miles: {financial(data.laneStatistics.oneDaysAverage.averageLoadMiles)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req pick*/}
                                {/*    up: {financial(data.laneStatistics.oneDaysAverage.averageDistanceFromRequestedPickup)} miles</small><br/>*/}
                                {/*<small>Avg. dist from req drop*/}
                                {/*    off: {financial(data.laneStatistics.oneDaysAverage.averageDistanceFromRequestedDrop)} miles</small><br/>*/}

                            </div>}

                    </div>
                </div>
            </div>


        </div>
        </div>
    </div>


    const financial = (x) => Number.parseFloat(x).toFixed(2);
    const financialNoDecimals = (x) => Number.parseFloat(x).toFixed(0);

    const columns = [
        {
            name: 'Equipment',
            selector: row => row.equipment,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.equipment}</div>
        },
        {
            name: 'Pick City',
            selector: row => row.pickCity + ', ' + row.pickState,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.pickCity + ', ' + row.pickState}</div>
        },

        {
            name: 'Drop City',
            selector: row => row.endCity + ', ' + row.endState,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.endCity + ', ' + row.endState}</div>
        },

        {
            id: 'lane',
            name: 'Line Haul Rate',
            selector: row => '$' + financial(row.laneAmount),
            sortable: true,
            cell: row => <div className="top-routes-dollar-text">${financialNoDecimals(row.laneAmount)}</div>
        }, {
            id: 'pricePerMile',
            name: 'Price/Mile',
            selector: row => '$' + financial(row.pricePerMile),
            sortable: true,
            cell: row => <div className="top-routes-dollar-text">${financial(row.pricePerMile)}</div>
        },
    ];

    let data = [];


    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            LaneService.getCityOptionsTwo(user.sub, token).then(
                response => {
                    response.collection.sort((a, b) => parseFloat(b.laneAmount) - parseFloat(a.laneAmount));

                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.collection,
                        subscription: response.subscription.status,
                        subscriptionType: response.subscription.subscriptionType
                    });
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log(''));
    }, []);


    return (
        <div>
            <div className="bg-image">
                {!state.showResult && (
                    <div className="text-center p-3">
                        <PageLoader></PageLoader>
                    </div>
                )}

                {state.showResult && <div>

                    <div className="container-fluid">
                        <div>
                            <p className="dashboard-text container-fluid">&nbsp;Top 50+ Routes across USA with highest rate per mile
                                over the last 30 days. Click on the right arrow, to get the more details on 30, 15, 7 and previous
                                days lane pricing.</p>
                        </div>
                        <header className="top-routes-text ">

                            <DataTable
                                // dense={true}
                                customStyles={customStyles}
                                striped={true}
                                columns={columns}
                                data={state.apiMessage}
                                // defaultSortFieldId="lane"
                                defaultSortAsc={false}
                                direction="auto"
                                fixedHeaderScrollHeight="300px"
                                pagination
                                responsive
                                subHeaderAlign="right"
                                subHeaderWrap
                                expandableRows expandableRowsComponent={ExpandedComponent}
                            />
                        </header>
<br/><br/><br/>

                    </div>

                </div>}
            </div>
        </div>
    );
};

export default withAuthenticationRequired(TopLanes, {
    onRedirecting: () => <PageLoader/>,
});
