import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {MobileNavBarTab} from "./mobile-nav-bar-tab";
import {NavBarTab} from "../desktop/nav-bar-tab";

export const MobileNavBarTabs = ({handleClick}) => {
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();

    return (
        <div className="mobile-nav-bar__tabs">

            {isAuthenticated && (
                <>
                    <MobileNavBarTab
                        path="/dashboard"
                        label="Dashboard"
                        handleClick={handleClick}
                    />
                    <MobileNavBarTab
                        path="/rates"
                        label="Rates"
                        handleClick={handleClick}
                    />
                    <MobileNavBarTab
                        path="/rfq"
                        label="RFQ"
                        handleClick={handleClick}
                    />
                    {/*<MobileNavBarTab*/}
                    {/*    path="/top-lanes"*/}
                    {/*    label="Top Lanes"*/}
                    {/*    handleClick={handleClick}*/}
                    {/*/>*/}
                    <MobileNavBarTab
                        path="/settings"
                        label="Settings"
                        handleClick={handleClick}
                    />
                    {isAuthenticated && (user.email == 'brad.kozak@farelanes.com' || user.email == 'casey.larkin@farelanes.com' || user.email == 'andrew.cohen@farelanes.com' || user.email == 'neal.huffman@farelanes.com' || user.email == 'kapil.reddy@farelanes.com') && (
                        <MobileNavBarTab
                            path="/insights"
                            label="Insights"
                            handleClick={handleClick}
                        />
                    )}

                    {isAuthenticated && (user.email == 'brad.kozak@farelanes.com' || user.email == 'casey.larkin@farelanes.com' || user.email == 'andrew.cohen@farelanes.com' || user.email == 'neal.huffman@farelanes.com' || user.email == 'kapil.reddy@farelanes.com') && (
                        <MobileNavBarTab
                            path="/rfq"
                            label="RFQ"
                            handleClick={handleClick}
                        />
                    )}

                    {isAuthenticated && (user.email == 'brad.kozak@farelanes.com' || user.email == 'casey.larkin@farelanes.com' || user.email == 'andrew.cohen@farelanes.com' || user.email == 'neal.huffman@farelanes.com' || user.email == 'kapil.reddy@farelanes.com') && (
                        <MobileNavBarTab
                            path="/admin"
                            label="Administration"
                            handleClick={handleClick}
                        />
                    )}
                </>
            )}
        </div>
    );
};
