import React from "react";
import {Auth0Features} from "../components/auth0-features";
import {HeroBanner} from "../components/hero-banner";
import {PageLayout} from "../components/page-layout";
import {isMobile} from 'react-device-detect';
import {Auth0FeaturesMobile} from "../components/auth0-features-mobile";

export const HomePage = () => {

    // if (isMobile) {
    //     return (
    //         <PageLayout>
    //             <HeroBanner/>
    //             <Auth0Features/>
    //         </PageLayout>
    //     )
    // }

    return (
        <PageLayout>
            <HeroBanner/>
            <Auth0Features/>
        </PageLayout>
    )
};


//
//
// import React from "react";
// import { Auth0Features } from "../components/auth0-features";
// import { HeroBanner } from "../components/hero-banner";
// import { PageLayout } from "../components/page-layout";
// import {isMobile} from 'react-device-detect';
// const HomePage = () => {
//     return (
//         <PageLayout>
//             <HeroBanner/>
//             <Auth0Features/>
//         </PageLayout>
//     )
// };
//
// export default HomePage;
