import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {CSVLink} from "react-csv";
import dayjs from "dayjs";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import DataGrid from "react-data-grid";
import 'react-data-grid/lib/styles.css';

export const DailyLaneDataAnalytics = () => {

    const columnsDataGrid = [

        {key: 'equipment', name: 'Equipment'},
        {key: 'dropDate', name: 'Drop Date'},
        {key: 'totalMiles', name: 'Total miles'},
        {key: 'loads', name: 'Total Number of Loads.'},
        {key: 'averagePricePerMile', name: 'Average ($) price/mile'}
    ];


// get functions to build form with useForm() hook
    const { register, handleSubmit, reset } = useForm();
    const financial = (x) => Number.parseFloat(x).toFixed(2);
    // user state for form
    const [farelanesUser, setFarelanesUser] = useState(null);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [equipmentValue, setEquipmentValue] = useState(  [
        {value: 'apex', label: 'Apex'},
        {value: 'integra', label: 'Integra'},
    ]);
    const [equipmentOptions, setEquipmentOptions] = useState( {value: 'apex', label: 'Apex'});
    const columns = [
        {
            name: 'Pick City',
            selector: row => row.key.pickCity + ', ' + row.key.pickState,
            sortable: true,
        },

        {
            name: 'Drop City',
            selector: row => row.key.endCity + ', ' + row.key.endState,
            sortable: true,
        },

        {
            id: 'pairs',
            name: '#.Pairs',
            selector: row => row.recordPairs,
            descending: true,
            sortable: true,
        },

        {
            id: 'lane',
            name: 'Line Haul Rate',
            selector: row => '$' + financial(row.totalLaneAmount),
            sortable: true,
        },
        {
            id: 'lane',
            name: 'Average Line Haul Rate',
            selector: row => '$' + financial(row.totalLaneAmount / row.recordPairs),
            sortable: true
        }
    ];

    function handleEquipmentChange(value) {
        setEquipmentOptions(value);
    };

    async function handleSearch() {
        const token = await getAccessTokenSilently();

        let text = ''
        for (let i = 0; i < equipmentValue.length; i++) {
            text += equipmentValue[i].value + ",";
        }

        setState({
            message: "loading..",
            loading: true,
            data: []
        });

        UserService.laneAnalytics(startDate.getTime(), endDate.getTime(), token).then(
            response => {
                setData(response.data);
                setState({
                    // content: response.data,
                    loading: false,
                    message: 'Success!'
                });

            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    }


    const [data, setData] = useState(new Date());
    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,

        // startDate: new Date(),
        // endDate: new Date(),
        content: '',
        message: '',

    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data
                    });
                    setFarelanesUser({ firstName: response.data.auth0GivenName, lastName: response.data.auth0FamilyName })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);




    function onSubmit(data) {
        // display form data on submit
        return false;
    }
    return (
        <div className="container-fluid">
            <h5 className="">Search - Lane Data Pairs by Date Range</h5>
            <div className="">
                {farelanesUser &&
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-row AppFonts">

                            <div className="col-sm-">
                                <label htmlFor="exampleFormControlFile1"><b>Drop Date (Start)</b></label>
                                <DatePicker className="pclass" showIcon value={startDate} selected={startDate}
                                            onChange={(date) => setStartDate(date)}/>
                            </div>



                            <div className="col-sm-">
                                <label htmlFor="exampleFormControlFile1"><b>Drop Date (End)</b></label>
                                <DatePicker className="pclass" showIcon value={endDate} selected={endDate}
                                            onChange={(date) => setEndDate(date)}/>
                            </div>

                            <div className="col-sm-2">
                                {/*<label htmlFor="exampleFormControlFile1"><b>[General]</b></label>*/}
                                <br/>
                                <button
                                    className="button__settings-save lane-data-a-button"
                                    onClick={handleSearch}
                                    disabled={state.loading}
                                >
                                    {state.loading && (
                                        <span className="spinner-grow"></span>
                                    )}
                                    <span>&nbsp;Search&nbsp;</span>

                                </button>
                                <br/>
                            </div>
                            <hr/>
                        </div>


                    </form>
                }

                <div className="container">
                    <hr/>

                    {data && data.length > 0 &&
                        <DataGrid
                            className="rdg-light"
                            style={{height: '400px'}}
                            columns={columnsDataGrid}
                            rows={data}/>


                        }


                        {data && data.length ==0 &&
                            <p className="alert">No records found.</p>
                        }


                    {data && data.length >0 &&
                        <CSVLink data={data}>
                            <h4><u>Download</u></h4>
                        </CSVLink>
                    }
                    </div>


                {!farelanesUser &&
                    <div className="text-center p-3">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }
            </div>
        </div>
    );
};

export default withAuthenticationRequired(DailyLaneDataAnalytics, {
    onRedirecting: () => <Loading />,
});
