import React, {useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import UserService from "../services/UserService";
import {useAuth0} from "@auth0/auth0-react";
import {PageLoader} from "./page-loader";

const fileTypes = ["XLS", "XLSX"];

function DragDrop() {

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const [file, setFile] = useState(null);
    const [apiFinished, setApiFinished] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const handleChange = async (file) => {
        const token = await getAccessTokenSilently();
        setApiFinished(false);
        setFile(file);
        setErrorMessage("");
        UserService.upload(file, token).then(
            response => {
                console.log(response);
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                setApiFinished(true);
                const link = document.createElement("a");
                let fileName = "farelanes-rfq-" + new Date().toLocaleString() + ".xls";
                link.download = fileName;
                const blob = new Blob([byteArray], {type: 'application/vnd.ms-excel'});
                link.href = URL.createObjectURL(blob);
                link.click();
                URL.revokeObjectURL(link.href);
                setErrorMessage("File " + fileName + "  has been downloaded. Please check your Downloads folder")
            },
            error => {
                if (error.response && error.response.status === 400) {
                    console.log(error.response.data)
                    setErrorMessage(error.response.data);
                    setApiFinished(true);
                }
            }
        )
    };
    return (
        <div className="container-fluid">
            <div>A request for quotation (RFQ) is just one of many helpful RFX documents that helps businesses exchange information.
            </div>
            <div className="file-download-text">
                <h4><a href="template.xlsx" download> <span className="alert-secondary file-download-text">Download Template</span> </a></h4>
            </div>
            <FileUploader handleChange={handleChange} name="file" types={fileTypes}/>
            {!apiFinished && (<div><PageLoader></PageLoader></div>)}

            <div>
                <br/>
                {errorMessage.length>0 &&
                <div className="alert-secondary file-download-text">{errorMessage}</div>
                }
            </div>
        </div>
    );
}

export default DragDrop;