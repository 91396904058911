import React, {useState} from "react";
import 'react-tabs/style/react-tabs.css';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {PageLoader} from "../components/page-loader";
import AsyncSelect from "react-select/async";
import LaneService from "../services/LaneService";
import {v4 as uuidv4} from 'uuid';
import Select from "react-select";
import {Table} from "react-bootstrap";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {abbrRegion, customStyles, daysStyles, equipmentTypeStyles, radiusStyles} from "./Constants";
import Modal from "react-modal";
import UserService from "../services/UserService";
import RatesMap from "../components/RatesMap";
import Ticker from "../components/Ticker";
import {FuelAndWeatherTicker} from "../components/FuelAndWeatherTicker";
import Marquee from "react-fast-marquee";
import {Parser} from "html-to-react";

export const RateTable = () => {
    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
    });
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let [pickCityDetails, setPickCityDetails] = React.useState(undefined);
    let [dropCityDetails, setDropCityDetails] = React.useState(undefined);
    let [pickCityWeatherDetails, setPickCityWeatherDetails] = React.useState(undefined);
    let [dropCityWeatherDetails, setDropCityWeatherDetails] = React.useState(undefined);
    let [pickCityFuelDetails, setPickCityFuelDetails] = React.useState(undefined);
    let [dropCityFuelDetails, setDropCityFuelDetails] = React.useState(undefined);


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const equipmentOptions = [
        {value: 'Auto Carrier', label: 'Auto Carrier'},
        {value: 'Container', label: 'Container'},
        {value: 'Conestoga', label: 'Conestoga'},
        {value: 'Dry Van', label: 'Dry Van'},
        {value: 'Flatbed', label: 'Flatbed'},
        {value: 'Hopper Bottom', label: 'Hopper Bottom'},
        {value: 'Lowboy', label: 'Lowboy'},
        {value: 'Double Drop', label: 'Double Drop'},
        {value: 'Maxi', label: 'Maxi'},
        {value: 'Power Only', label: 'Power Only'},
        {value: 'Pneumatic', label: 'Pneumatic'},
        {value: 'Reefer', label: 'Reefer'},
        {value: 'Straight Box', label: 'Straight Box'},
        {value: 'Step Deck', label: 'Step Deck'},
        {value: 'Sprinter', label: 'Sprinter'},
        {value: 'Tanker', label: 'Tanker'},
        {value: 'Truck and Trailer', label: 'Truck & Trailer'},
    ];

    const radiusOptions = [
        {value: 50, label: '50'},
        {value: 100, label: '100'},
        {value: 150, label: '150'},
        {value: 200, label: '200'}
    ];

    const dayRangeOptions = [
        {value: 120, label: '120'},
        {value: 60, label: '60'},
        {value: 30, label: '30'},
        {value: 7, label: '7'},
    ];

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const [fistTime, setFistTime] = useState(true);
    const [inputPickCityValue, setPickCityValue] = useState([]);
    const [selectedPickCityValue, setSelectedPickCityValue] = useState([]);

    const [inputDropCityValue, setDropCityValue] = useState([]);
    const [selectedDropCityValue, setSelectedDropCityValue] = useState([]);

    const [radiusValue, setRadiusValue] = useState([{value: 200, label: '200 mi'}]);
    const [dropRadiusValue, setDropRadiusValue] = useState([{value: 200, label: '200 mi'}]);
    const [equipmentArray, setEquipmentArray] = useState([]);

    const [dashReady, setDashReady] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState(false);

    const mStyle = {
        'min-width': '700px'
    }

    const handlePickCityRadiusChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_pick_city_radius_old = data[rowIndex].request_pick_city_radius;
        data[rowIndex].request_pick_city_radius = event;
        setEquipmentArray[row_id] = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };

    const handleDropRadiusChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_drop_city_radius_old = data[rowIndex].request_drop_city_radius;
        data[rowIndex].request_drop_city_radius = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };


    const [originalData, setOriginalData] = useState([]);
    const [data, setData] = useState([]);


    // load options using API call
    const loadOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getCityOptions(inputValue, token).then(
            response => {
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    };


    const someArray = [];


    // handle input change event ONE
    const handleEquipmentChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_equipment_type_old = data[rowIndex].request_equipment_type;
        data[rowIndex].request_equipment_type = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };

    const [dayRangeValue, setDayRangeValue] = useState([]);


    const handleDayRangeValueChange = (event, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_number_of_days_old = data[rowIndex].request_number_of_days;
        data[rowIndex].request_number_of_days = event;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    };

    const handlePickCityInputChange = (value, row_id) => {
        // let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        // data[rowIndex].request_pick_city = value;
        // setSelectedPickCityValue[row_id] = (value);
    };

    const handleDropCityInputChange = (value, row_id) => {
        // let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        // data[rowIndex].request_pick_city = value;
        // setSelectedPickCityValue[row_id] = (value);
    };

    // handle selection
    const handlePickCityChange = (value, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_pick_city_old = data[rowIndex].request_pick_city;
        data[rowIndex].request_pick_city = value;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    }

    const handleDropCityChange = (value, row_id) => {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        data[rowIndex].request_drop_city_old = data[rowIndex].request_drop_city;
        data[rowIndex].request_drop_city = value;
        setData(data.filter(item => item.row_id !== 'mutate_data'));
    }


    // handle selection

    async function openModal(dash_item) {

        setState({
            ...state,
            showResult: false
        });
        const token = await getAccessTokenSilently();
        UserService.citySearch(dash_item.request_pick_city.id, token).then(response => {
            console.log(response);
            setPickCityDetails(response.data);
        });

        UserService.citySearch(dash_item.request_drop_city.id, token).then(response => {
            console.log(response);
            setDropCityDetails(response.data);
        });

        UserService.cityFuelSearch(dash_item.request_pick_city.id, token).then(response => {
            setPickCityFuelDetails(response.data);
        });

        UserService.cityFuelSearch(dash_item.request_drop_city.id, token).then(response => {
            setDropCityFuelDetails(response.data);
        });

        // UserService.cityWeatherSearch(dash_item.request_drop_city.city + ',' + dash_item.request_drop_city.stateName, token).then(response => {
        //     setDropCityWeatherDetails(response.data);
        // });
        //
        // UserService.cityWeatherSearch(dash_item.request_pick_city.city + ',' + dash_item.request_pick_city.stateName, token).then(response => {
        //     setPickCityWeatherDetails(response.data);
        // });

        setIsOpen(true);
    }


    function clickHandler(id) {
        setData(data.concat({row_id: uuidv4(), id: null}))
    }

    async function moveRecord(event, upDown) {
        let dataLength = data.length;
        let rowIndex = data.findIndex(item => item['row_id'] === event);
        let message = "Move lane ?" + upDown;
        let recordNumber;
        if (data[rowIndex].request_pick_city_radius !== undefined && data[rowIndex].request_drop_city !== null) {
            let pickState = data[rowIndex].request_pick_city.stateName;
            let pickCity = data[rowIndex].request_pick_city.city;
            let dropCity = data[rowIndex].request_drop_city.city;
            let dropState = data[rowIndex].request_drop_city.stateName;
            recordNumber = data[rowIndex].recordOrder;
            message = 'Move lane pair ' + pickCity + ', ' + abbrRegion(pickState, 'abbr') + ' to ' + dropCity + ', ' + abbrRegion(dropState, 'abbr') + ' ' + upDown;
        }

        if (recordNumber == 1 && upDown == 'up') {
            confirmAlert({
                title: 'Cannot move the lane up. trying moving it down instead',
                message: message,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            console.log('Cannot move the lane up. trying moving it down instead.')
                        }
                    }
                ]
            });
            return;
        }

        if (recordNumber == dataLength && upDown == 'down') {
            confirmAlert({
                title: 'Cannot move the lane down. trying moving it up instead',
                message: message,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            console.log('Cannot move the lane down. trying moving it up instead.')
                        }
                    }
                ]
            });
            return;
        }

        const token = await getAccessTokenSilently();

        if (!data[rowIndex].id) {
            setData(data.filter(item => item.row_id !== event));
            return;
        }

        //Now sync() changes to BackEnd system.
        LaneService.shuffleDashBoardItem(user, data[rowIndex].id, upDown, token).then(
            response => {
                setData(response.data.filter(item => item.row_id !== 'mutate_data'));
            }
        )

        // confirmAlert({
        //     title: 'Move Lane' + upDown,
        //     message: message,
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: async () => {
        //                 const token = await getAccessTokenSilently();
        //
        //                 if (!data[rowIndex].id) {
        //                     setData(data.filter(item => item.row_id !== event));
        //                     return;
        //                 }
        //
        //                 //Now sync() changes to BackEnd system.
        //                 LaneService.shuffleDashBoardItem(user, data[rowIndex].id, upDown, token).then(
        //                     response => {
        //                         setData(response.data.filter(item => item.row_id !== 'mutate_data'));
        //                     }
        //                 )
        //             }
        //         },
        //         {
        //             label: 'No',
        //             onClick: () => {
        //                 console.log('Canceling action. Do nothing')
        //             }
        //         }
        //     ]
        // });
    };

    async function deleteRow(event) {
        let rowIndex = data.findIndex(item => item['row_id'] === event);
        let message = "Confirm delete ?"
        if (data[rowIndex].request_pick_city_radius !== undefined && data[rowIndex].request_drop_city !== null) {
            let pickState = data[rowIndex].request_pick_city.stateName;
            let pickCity = data[rowIndex].request_pick_city.city;
            let dropCity = data[rowIndex].request_drop_city.city;
            let dropState = data[rowIndex].request_drop_city.stateName;
            message = 'Delete lane pair ' + pickCity + ', ' + abbrRegion(pickState, 'abbr') + ' to ' + dropCity + ', ' + abbrRegion(dropState, 'abbr');
        }

        const token = await getAccessTokenSilently();

        if (!data[rowIndex].id) {
            setData(data.filter(item => item.row_id !== event));
            return;
        }


        //Now sync() changes to BackEnd system.
        LaneService.deleteDashBoardItem(user, data[rowIndex].id, token).then(
            response => {
                setData(response.data.filter(item => item.row_id !== 'mutate_data'));
            }
        )

        // confirmAlert({
        //     title: 'Delete Lane',
        //     message: message,
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: async () => {
        //                 const token = await getAccessTokenSilently();
        //
        //                 if (!data[rowIndex].id) {
        //                     setData(data.filter(item => item.row_id !== event));
        //                     return;
        //                 }
        //
        //                 //Now sync() changes to BackEnd system.
        //                 LaneService.deleteDashBoardItem(user, data[rowIndex].id, token).then(
        //                     response => {
        //                         setData(response.data.filter(item => item.row_id !== 'mutate_data'));
        //                     }
        //                 )
        //             }
        //         },
        //         {
        //             label: 'No',
        //             onClick: () => {
        //                 console.log('Canceling action. Do nothing')
        //             }
        //         }
        //     ]
        // });
    };


    function deleteHandler(event) {
        setData(data.filter(item => item.row_id !== event))
    }

    async function laneSearch(row_id) {
        console.log('Great ERA starting');
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        let message = "Add City pairs, radius, and miles along with Equipment";
        if (

            data[rowIndex].request_pick_city !== undefined &&
            data[rowIndex].request_pick_city.city !== undefined &&
            data[rowIndex].request_pick_city.stateName !== undefined &&
            data[rowIndex].request_drop_city !== undefined &&
            data[rowIndex].request_drop_city.city !== undefined &&
            data[rowIndex].request_drop_city.stateName !== undefined &&
            data[rowIndex].request_equipment_type !== undefined &&
            data[rowIndex].request_equipment_type.value !== undefined &&
            data[rowIndex].request_pick_city_radius !== undefined &&
            data[rowIndex].request_pick_city_radius.value !== undefined &&
            data[rowIndex].request_drop_city_radius !== undefined &&
            data[rowIndex].request_drop_city_radius.value !== undefined &&
            data[rowIndex].request_number_of_days &&
            data[rowIndex].request_number_of_days.value) {
            let pickState = data[rowIndex].request_pick_city.stateName;
            let pickCity = data[rowIndex].request_pick_city.city;
            let dropCity = data[rowIndex].request_drop_city.city;
            let dropState = data[rowIndex].request_drop_city.stateName;
            message = 'Save lane pair ' + pickCity + ', ' + abbrRegion(pickState, 'abbr') + ' to ' + dropCity + ', ' + abbrRegion(dropState, 'abbr');
        } else {
            confirmAlert({
                title: 'Cannot save empty city or mile record(s)',
                message: message,
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                            console.log('New Item dash errors so allowing the user to start over.')
                        }
                    }
                ]
            });
            return;
        }

        setDashReady(false);
        const token = await getAccessTokenSilently();
        LaneService.getLaneStatistics(data[rowIndex].request_pick_city.city,
            data[rowIndex].request_pick_city.stateName,
            data[rowIndex].request_drop_city.city,
            data[rowIndex].request_drop_city.stateName,
            data[rowIndex].request_equipment_type.value,
            data[rowIndex].request_pick_city_radius.value,
            data[rowIndex].request_drop_city_radius.value,
            data[rowIndex].request_number_of_days.value,
            token).then(
            response => {
                // data.find(x => x.row_id == row_id).raterPerMile=response.data.thirtyDaysAverage.costPerMile;
                const updatedData = data.map(x => (x.row_id === row_id ? {
                    ...x,
                    ratePerMile: response.data.thirtyDaysAverage.costPerMile,
                    ratePerLoad: response.data.thirtyDaysAverage.averageLoadRate,
                    confidenceInterval: response.data.thirtyDaysAverage.confidenceInterval,
                    confidenceIntervalColor: response.data.thirtyDaysAverage.confidenceIntervalColor,
                } : x));
                setData(updatedData);
                //The save() needs to happen here and needs to be in SYNC ....with data
                LaneService.syncDashBoard(updatedData, token).then(
                    response => {
                        setData(response.data.filter(item => item.row_id !== 'mutate_data'));
                        setDashReady(true);
                    }
                )

            },
            error => {
                if (error.response && error.response.status === 401) {
                    // AuthService.logout();
                    // window.location.replace('/login');
                    // EventBus.dispatch("logout");
                }
            }
        );

        // confirmAlert({
        //     title: 'Save Lane',
        //     message: message,
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: async () => {
        //                 setDashReady(false);
        //                 console.log('{}{}{}{}');
        //                 let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        //                 console.log(data[rowIndex].request_pick_city)
        //                 const token = await getAccessTokenSilently();
        //                 LaneService.getLaneStatistics(data[rowIndex].request_pick_city.city,
        //                     data[rowIndex].request_pick_city.stateName,
        //                     data[rowIndex].request_drop_city.city,
        //                     data[rowIndex].request_drop_city.stateName,
        //                     data[rowIndex].request_equipment_type.value,
        //                     data[rowIndex].request_pick_city_radius.value,
        //                     data[rowIndex].request_drop_city_radius.value,
        //                     data[rowIndex].request_number_of_days.value,
        //                     token).then(
        //                     response => {
        //                         console.log('Async() trigger ....');
        //                         console.log(response.data.thirtyDaysAverage.costPerMile);
        //                         console.log(response.data.thirtyDaysAverage.averageLoadRate);
        //                         // data.find(x => x.row_id == row_id).raterPerMile=response.data.thirtyDaysAverage.costPerMile;
        //                         const updatedData = data.map(x => (x.row_id === row_id ? {
        //                             ...x,
        //                             ratePerMile: response.data.thirtyDaysAverage.costPerMile,
        //                             ratePerLoad: response.data.thirtyDaysAverage.averageLoadRate,
        //                             confidenceInterval: response.data.thirtyDaysAverage.confidenceInterval,
        //                             confidenceIntervalColor: response.data.thirtyDaysAverage.confidenceIntervalColor,
        //                         } : x));
        //                         setData(updatedData);
        //                         //The save() needs to happen here and needs to be in SYNC ....with data
        //                         LaneService.syncDashBoard(updatedData, token).then(
        //                             response => {
        //                                 console.log(response);
        //                                 setData(response.data.filter(item => item.row_id !== 'mutate_data'));
        //                                 setDashReady(true);
        //                             }
        //                         )
        //
        //                     },
        //                     error => {
        //                         if (error.response && error.response.status === 401) {
        //                             // AuthService.logout();
        //                             // window.location.replace('/login');
        //                             // EventBus.dispatch("logout");
        //                         }
        //                     }
        //                 );
        //
        //             }
        //         },
        //         {
        //             label: 'No',
        //             onClick: () => {
        //                 console.log('Canceling action. Do nothing')
        //             }
        //         }
        //     ]
        // });


    }


    async function favorite(row_id) {
        let rowIndex = data.findIndex(item => item['row_id'] === row_id);
        if (
            data[rowIndex].request_pick_city !== undefined &&
            data[rowIndex].request_pick_city.city !== undefined &&
            data[rowIndex].request_pick_city.stateName !== undefined &&
            data[rowIndex].request_drop_city !== undefined &&
            data[rowIndex].request_drop_city.city !== undefined &&
            data[rowIndex].request_drop_city.stateName !== undefined &&
            data[rowIndex].request_equipment_type !== undefined &&
            data[rowIndex].request_equipment_type.value !== undefined &&
            data[rowIndex].request_pick_city_radius !== undefined &&
            data[rowIndex].request_pick_city_radius.value !== undefined &&
            data[rowIndex].request_drop_city_radius !== undefined &&
            data[rowIndex].request_drop_city_radius.value !== undefined &&
            data[rowIndex].request_number_of_days &&
            data[rowIndex].request_number_of_days.value) {
            const token = await getAccessTokenSilently();
            let favoriteValue = data[rowIndex].favorite;
            if (null == favoriteValue || undefined == favoriteValue) {
                data[rowIndex].favorite = true;
            } else {
                data[rowIndex].favorite = !data[rowIndex].favorite;
            }
            setData(data.filter(item => item.row_id !== 'mutate_data'));
            LaneService.syncDashBoard(data, token).then(
                response => {
                    setData(response.data.filter(item => item.row_id !== 'mutate_data'));
                }
            )
        } else {
            console.log('Not eligible record for favorites');
        }
    }


    const callApi = async () => {
        const token = await getAccessTokenSilently();
        setDashReady(false);
        UserService.userSubscriptionStatusPair(token, user).then(
            response => {
                setSubscriptionType(response.data.subscriptionType);
                console.log('Sub rtype set ' + response.data.subscriptionType);
            }
        );

        if (fistTime) {

            LaneService.loadDashBoard(token).then(
                response => {
                    setFistTime(false);
                    setDashReady(true);
                    setData(response);
                    setOriginalData(response);
                }
            );
        } else {
            setDashReady(true);
        }
    };
    const style = {
        color: 'green'
    };

    React.useEffect(() => {
        callApi().then(r => console.log('finish refresh'));
    }, [data]);
    const customStylesForModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    async function weatherAndFuelDataFetch(pickCityId, pickCity, pickState, dropCityId, dropCity, dropState) {
        if (subscriptionType == 'Gold' || subscriptionType == 'Platinum') {
            try {
                setState({
                    ...state,
                    showResult: false,
                    apiMessage: 'Fetching latest fuel and weather ..'
                });
                const token = await getAccessTokenSilently();
                UserService.getWeatherAndFuelTicker(pickCityId, pickCity, pickState, dropCityId, dropCity, dropState, token).then(
                    response => {
                        setState({
                            ...state,
                            showResult: true,
                            apiMessage: response.data,
                        });
                    }
                );
            } catch (error) {
                setState({
                    ...state,
                    error: error.error,
                });
            }
        } else {
            setState({
                ...state,
                showResult: true,
                apiMessage: 'Fuel and Weather feed available only in Gold and Platinum',
            });
        }


    }

    return (


        <div className="container-fluid">

            {!dashReady && (
                <div><PageLoader></PageLoader><span className="loader-text">&nbsp; Loading your dashboard with updated prices....</span>
                </div>)}

            {dashReady && (

                <div className=" dashboard-text">

                    <div className="container-fluid">
                        {/*{!state.showResult && (<div className="text-center p-3">*/}
                        {/*    /!*<PageLoader></PageLoader>*!/*/}
                        {/*</div>)}*/}
                        {/*{state.showResult && (<div>*/}

                        {/*        <Marquee className="marquee-fuel-ticker" pauseOnClick={true} pauseOnHover={true} speed={6}>*/}
                        {/*            <div className="fuel-ticker">*/}
                        {/*                {Parser().parse(state.apiMessage)}*/}
                        {/*            </div>*/}
                        {/*        </Marquee>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    <div className="add_lane_dashboard_button right-float">
                        <button onClick={clickHandler}>+ LANE</button>
                    </div>
                    <Table className="dashboard-table">
                        <thead className="dashboard-header">
                        <tr>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Equipment - Dry Van, Reefer ...">
                                    Equipment
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Select a Pick City or Zip Code">
                                    Pick City Or Zip
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Select radius in miles for Pick City or Zip Code">
                                    Radius
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Select a Drop City or Zip Code">
                                   Drop City or Zip
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Select radius in miles for Drop City or Zip Code">
                                    Radius
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Last number of days">
                                    Days
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Cost of a mile in Dollars">
                                    $/mile
                                </span>
                            </th>
                            <th>
                           <span type="button" className="" data-toggle="tooltip"
                                 data-placement="top" title="Total lane price in Dollars">
                                    $/load
                                </span>
                            </th>
                            <th>
                                <span type="button" className="" data-toggle="tooltip"
                                      data-placement="top" title="Confidence Index">
                                    CI
                                </span>
                            </th>
                            <th>Save</th>
                            <th>Delete</th>
                            <th>&nbsp;Fav</th>
                            {/*<th>Fuel</th>*/}
                            <th>Down</th>
                            <th>Up</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>

                        {data.map(dash_item => (

                            <tr key={dash_item.row_id}>
                                {/*<td>{dash_item.recordOrder}</td>*/}
                                <td>
                                    <Select
                                        label="What equipment"
                                        options={equipmentOptions}
                                        styles={equipmentTypeStyles}
                                        value={dash_item.request_equipment_type}
                                        // onChange={handleEquipmentChange}
                                        onChange={event => handleEquipmentChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td className="asyncselect">
                                    <AsyncSelect
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        cacheOptions
                                        styles={customStyles}
                                        defaultOptions
                                        value={dash_item.request_pick_city}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={event => handlePickCityInputChange(event, dash_item.row_id)}
                                        onChange={event => handlePickCityChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td>
                                    <Select
                                        label="What radius"
                                        options={radiusOptions}
                                        styles={radiusStyles}
                                        value={dash_item.request_pick_city_radius}
                                        onChange={event => handlePickCityRadiusChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td className="asyncselect">
                                    <AsyncSelect
                                        cacheOptions
                                        styles={customStyles}
                                        defaultOptions
                                        value={dash_item.request_drop_city}
                                        getOptionLabel={e => e.city + ', ' + e.stateName}
                                        getOptionValue={e => e.city}
                                        loadOptions={loadOptions}
                                        onInputChange={event => handleDropCityInputChange(event, dash_item.row_id)}
                                        onChange={event => handleDropCityChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td className="asyncselect">
                                    <Select
                                        label="What radius"
                                        styles={radiusStyles}
                                        options={radiusOptions}
                                        value={dash_item.request_drop_city_radius}
                                        onChange={event => handleDropRadiusChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td>
                                    <Select
                                        label="What day"
                                        styles={daysStyles}
                                        options={dayRangeOptions}
                                        value={dash_item.request_number_of_days}
                                        onChange={event => handleDayRangeValueChange(event, dash_item.row_id)}
                                    />
                                </td>
                                <td>
                                    {dash_item.ratePerMile && <span
                                        className="dashboard-dollar-prices">${(Math.round(dash_item.ratePerMile * 100) / 100).toFixed(2)}</span>}
                                </td>
                                <td>
                                    {dash_item.ratePerLoad && <span
                                        className="dashboard-dollar-prices">${(Math.round(dash_item.ratePerLoad * 100) / 100).toFixed(0)}</span>}
                                </td>
                                <td>
                                    <span
                                        style={{color: dash_item.confidenceIntervalColor}}> {dash_item.confidenceInterval}</span>
                                </td>
                                <td className="heart-button">


                                    {/*{dash_item.request_number_of_days.value}*/}
                                    {/*{dash_item.request_number_of_days_old && dash_item.request_number_of_days_old.value}*/}


                                    <button onClick={(e) => {
                                        laneSearch(dash_item.row_id).then(console.log('k'));
                                    }} id={dash_item.row_id}>

                                            <span className={
                                                (dash_item.request_equipment_type_old && dash_item.request_equipment_type_old.value) ||
                                                (dash_item.request_pick_city_old && dash_item.request_pick_city_old.city) ||
                                                (dash_item.request_drop_city_old && dash_item.request_drop_city_old.city) ||
                                                (dash_item.request_pick_city_radius_old && dash_item.request_pick_city_radius_old.value) ||
                                                (dash_item.request_drop_city_radius_old && dash_item.request_drop_city_radius_old.value) ||
                                                (dash_item.request_number_of_days_old && dash_item.request_number_of_days_old.value) || dash_item.id == null
                                                    ? "red-font"
                                                    : "normal-font"
                                            }>
                                                      <i className="bi bi-floppy"></i>
                                            </span>

                                    </button>


                                </td>
                                <td>
                                    <button onClick={(e) => {
                                        deleteRow(dash_item.row_id)
                                    }} id={dash_item.row_id}><i className="bi bi-trash3 big-trash"></i></button>
                                </td>

                                <td className="heart-button">
                                    {/*{dash_item.request_number_of_days.value}*/}
                                    {/*{dash_item.request_number_of_days_old && dash_item.request_number_of_days_old.value}*/}
                                    <button onClick={(e) => {
                                        favorite(dash_item.row_id).then(console.log('k'));
                                    }} id={dash_item.row_id}>{dash_item.favorite &&
                                        <i className="bi bi-heart-fill"></i>}
                                        {!dash_item.favorite && <i className="bi bi-heart"></i>}
                                    </button>
                                </td>
                                {/*<button><i className="bi bi-heart-fill"></i></button>*/}


                                {/*<td className="heart-button">*/}
                                {/*    <button onClick={(e) => {*/}
                                {/*        weatherAndFuelDataFetch(dash_item.request_pick_city.id, dash_item.request_pick_city.city, dash_item.request_pick_city.stateName, dash_item.request_drop_city.id, dash_item.request_drop_city.city, dash_item.request_drop_city.stateName);*/}
                                {/*    }} id={dash_item.row_id}><i className="bi bi-fuel-pump-diesel"></i>*/}
                                {/*    </button>*/}
                                {/*</td>*/}

                                <td className="heart-button">
                                    {/*<i className="bi bi-three-dots"></i>*/}
                                    <button onClick={(e) => {
                                        moveRecord(dash_item.row_id, 'down')
                                    }} id={dash_item.row_id}><i className="bi-arrow-down-circle"></i></button>
                                </td>

                                <td className="heart-button">
                                    <button onClick={(e) => {
                                        moveRecord(dash_item.row_id, 'up')
                                    }} id={dash_item.row_id}><i className="bi-arrow-up-circle"></i></button>
                                </td>

                                <td>
                                    <div>
                                        {subscriptionType == 'Gold' || subscriptionType == 'Platinum' && (
                                            <button onClick={() => openModal(dash_item)}><i
                                                className="bi bi-info-lg"></i>
                                            </button>
                                        )}
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onAfterOpen={afterOpenModal}
                                            onRequestClose={closeModal}
                                            style={customStylesForModal}
                                            style={{
                                                overlay: {
                                                    position: 'fixed',
                                                    top: 20,
                                                    left: 20,
                                                    right: 20,
                                                    bottom: 20,
                                                    backgroundColor: 'rgba(255, 255, 255, 0.75)'
                                                },
                                                content: {
                                                    position: 'absolute',
                                                    top: '170px',
                                                    left: '300px',
                                                    right: '300px',
                                                    bottom: '170px',
                                                    border: '1px solid #ccc',
                                                    background: '#fff',
                                                    overflow: 'auto',
                                                    WebkitOverflowScrolling: 'touch',
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    padding: '20px'
                                                }
                                            }}
                                        >

                                            <div className="container-fluid">
                                                {/*<button className="btn-success pop-close-button"*/}
                                                {/*        onClick={closeModal}>Close*/}
                                                {/*</button>*/}
                                                <hr/>
                                                <div
                                                    className="green-bg">

                                                    {pickCityDetails && pickCityDetails.city} {pickCityDetails && pickCityDetails.stateName}  &nbsp;
                                                    <span className="i-button"><i
                                                        className="bi bi-arrow-right"></i></span>
                                                    &nbsp;&nbsp;{dropCityDetails && dropCityDetails.city} {dropCityDetails && dropCityDetails.stateName}
                                                </div>
                                                <hr/>

                                                <div className="row">


                                                    <div className="col-md-6 popup-dashboard-vertical-divider">


                                                        <dl className="row">
                                                            <dt className="col-sm-3 popup-highlight-city">Pick City</dt>
                                                            <dd className="col-sm-9 popup-highlight-city">{pickCityDetails && pickCityDetails.city + ', ' + pickCityDetails.stateName}&nbsp;&nbsp;{pickCityDetails && pickCityDetails.timeZone}
                                                            </dd>

                                                            <dt className="col-sm-3 popup-highlight-city">Fuel</dt>
                                                            <dd className="col-sm-9 popup-highlight-city">{pickCityFuelDetails && pickCityFuelDetails.gas_prices[0].average} (Average) {pickCityFuelDetails && pickCityFuelDetails.gas_prices[0].lowest} (Lowest)
                                                            </dd>

                                                            {/*weather list*/}
                                                            {/*<dt className="col-sm-3 popup-highlight-city">Weather</dt>*/}
                                                            {/*<dd className="col-sm-9 popup-highlight-city">&nbsp;*/}
                                                            {/*</dd>*/}

                                                            {/*<dt className="col-sm-3 popup-highlight-city">Weather</dt>*/}
                                                            {/*<dd className="col-sm-9 popup-highlight-city">*/}
                                                            {/*    <p>{pickCityWeatherDetails && pickCityWeatherDetails.list && pickCityWeatherDetails.list[0].main.temp} (temp) &nbsp;&nbsp;*/}
                                                            {/*        {pickCityWeatherDetails && pickCityWeatherDetails.list && pickCityWeatherDetails.list[0].main.feels_like} (feels*/}
                                                            {/*        like)</p>*/}
                                                            {/*    <p>{pickCityWeatherDetails && pickCityWeatherDetails.list && pickCityWeatherDetails.list[0].main.temp_min} (temp*/}
                                                            {/*        min)*/}
                                                            {/*        &nbsp;{pickCityWeatherDetails && pickCityWeatherDetails.list && pickCityWeatherDetails.list[0].main.temp_max} (temp*/}
                                                            {/*        max)*/}
                                                            {/*    </p>*/}

                                                            {/*    <p>{pickCityWeatherDetails && pickCityWeatherDetails.list && pickCityWeatherDetails.list[0].clouds.all} (clouds*/}
                                                            {/*        )*/}

                                                            {/*    </p>*/}
                                                            {/*</dd>*/}


                                                            {/*{dropCityWeatherDetails && dropCityWeatherDetails.list && dropCityWeatherDetails.list.map(weather => {*/}
                                                            {/*    <dd>*/}
                                                            {/*        <span>{weather.dt_txt}</span>*/}
                                                            {/*    </dd>*/}
                                                            {/*})}*/}

                                                            <dt className="col-sm-3">Lat&Lng</dt>
                                                            <dd className="col-sm-9">
                                                                <p>{pickCityDetails && pickCityDetails.lat} & {pickCityDetails && pickCityDetails.lng} &
                                                                    density
                                                                    of {pickCityDetails && pickCityDetails.density} </p>
                                                            </dd>


                                                            <dt className="col-sm-3">Population</dt>
                                                            <dd className="col-sm-9">
                                                                <p>{pickCityDetails && pickCityDetails.population}</p>
                                                                <p>{pickCityDetails && pickCityDetails.populationProper} (Population
                                                                    Proper)</p>
                                                                <p>White
                                                                    ({pickCityDetails && pickCityDetails.race_white}%),
                                                                    Black
                                                                    ({pickCityDetails && pickCityDetails.race_black}%),
                                                                    Asian
                                                                    ({pickCityDetails && pickCityDetails.race_asian}%),
                                                                    Other
                                                                    ({pickCityDetails && pickCityDetails.race_other}%),
                                                                    Multiple
                                                                    ({pickCityDetails && pickCityDetails.race_multiple}%)</p>

                                                                <dl className="row">
                                                                    <dd className="col-sm-4">{pickCityDetails && pickCityDetails.male} (%
                                                                        Male)
                                                                    </dd>
                                                                    <dd className="col-sm-8">{pickCityDetails && pickCityDetails.femalePercentage} (%
                                                                        Female (%))
                                                                    </dd>
                                                                </dl>

                                                                <dl className="row">
                                                                    <dd className="col-sm-4">{pickCityDetails && pickCityDetails.married} (%
                                                                        Married)
                                                                    </dd>
                                                                    <dd className="col-sm-8">{pickCityDetails && pickCityDetails.familySize} (Family
                                                                        Size.)
                                                                    </dd>
                                                                </dl>

                                                            </dd>

                                                            <dt className="col-sm-3">Financial Data</dt>
                                                            <dd className="col-sm-9">

                                                                <p>${pickCityDetails && pickCityDetails.homeValue} (Avg.Home
                                                                    Value)</p>
                                                                <p>${pickCityDetails && pickCityDetails.rent_median} (Avg.Home
                                                                    Median Rent)</p>
                                                                <p>${pickCityDetails && pickCityDetails.householdIncomeMedian} (Median
                                                                    Income)</p>
                                                                <p>{pickCityDetails && pickCityDetails.incomeHouseHoldSixFigure} (
                                                                    House holds with 6 figure income (%))</p>
                                                                <p>{pickCityDetails && pickCityDetails.homeOwnership} (%
                                                                    Home Ownership)</p>

                                                                <p>{pickCityDetails && pickCityDetails.educationCollegeOrAbove} (%
                                                                    Education College or Above)</p>

                                                                <p>{pickCityDetails && pickCityDetails.laborForcePArticipation} (%
                                                                    Labor Force Participation)</p>


                                                            </dd>

                                                            <dt className="col-sm-3">Fips</dt>
                                                            <dd className="col-sm-9">
                                                                <p>{pickCityDetails && pickCityDetails.countyFips}</p>
                                                                <p>{pickCityDetails && pickCityDetails.countyFipsAll} (Fips
                                                                    All)</p>
                                                            </dd>
                                                            {/*<dt className="col-sm-3">Zips</dt>*/}
                                                            {/*<dd className="col-sm-9">*/}
                                                            {/*    <p className="small_zips">{pickCityDetails && pickCityDetails.zips} </p>*/}
                                                            {/*</dd>*/}


                                                        </dl>


                                                        {/*End of pick city details from us city data*/}
                                                    </div>

                                                    {/*End City*/}
                                                    <div className="col-md-6">


                                                        <dl className="row">
                                                            <dt className="col-sm-3 popup-highlight-city">Drop City</dt>
                                                            <dd className="col-sm-9 popup-highlight-city">{dropCityDetails && dropCityDetails.city + ', ' + dropCityDetails.stateName}&nbsp;&nbsp;{dropCityDetails && dropCityDetails.timeZone}
                                                            </dd>

                                                            <dt className="col-sm-3 popup-highlight-city">Fuel</dt>
                                                            <dd className="col-sm-9 popup-highlight-city">{dropCityFuelDetails && dropCityFuelDetails.gas_prices[0].average} (Average) {dropCityFuelDetails && dropCityFuelDetails.gas_prices[0].lowest} (Lowest)
                                                            </dd>

                                                            {/*weather list*/}
                                                            {/*<dt className="col-sm-3 popup-highlight-city">Weather</dt>*/}
                                                            {/*<dd className="col-sm-9 popup-highlight-city">&nbsp;*/}
                                                            {/*</dd>*/}

                                                            {/*<dt className="col-sm-3 popup-highlight-city">Weather</dt>*/}
                                                            {/*<dd className="col-sm-9 popup-highlight-city">*/}
                                                            {/*    <p>{dropCityWeatherDetails && dropCityWeatherDetails.list && dropCityWeatherDetails.list[0].main.temp} (temp) &nbsp;&nbsp;*/}
                                                            {/*        {dropCityWeatherDetails && dropCityWeatherDetails.list && dropCityWeatherDetails.list[0].main.feels_like} (feels*/}
                                                            {/*        like)</p>*/}
                                                            {/*    <p>{dropCityWeatherDetails && dropCityWeatherDetails.list && dropCityWeatherDetails.list[0].main.temp_min} (temp*/}
                                                            {/*        min)*/}
                                                            {/*        &nbsp;{dropCityWeatherDetails && dropCityWeatherDetails.list && dropCityWeatherDetails.list[0].main.temp_max} (temp*/}
                                                            {/*        max)*/}
                                                            {/*    </p>*/}

                                                            {/*    <p>{dropCityWeatherDetails && dropCityWeatherDetails.list && dropCityWeatherDetails.list[0].clouds.all} (clouds*/}
                                                            {/*        )*/}

                                                            {/*    </p>*/}
                                                            {/*</dd>*/}

                                                            <dt className="col-sm-3">Lat&Lng</dt>
                                                            <dd className="col-sm-9">
                                                                <p>{dropCityDetails && dropCityDetails.lat} & {dropCityDetails && dropCityDetails.lng} &
                                                                    density
                                                                    of {dropCityDetails && dropCityDetails.density} </p>
                                                            </dd>


                                                            <dt className="col-sm-3">Population</dt>
                                                            <dd className="col-sm-9">
                                                                <p>{dropCityDetails && dropCityDetails.population}</p>
                                                                <p>{dropCityDetails && dropCityDetails.populationProper} (Population
                                                                    Proper)</p>
                                                                <p>White
                                                                    ({dropCityDetails && dropCityDetails.race_white}%),
                                                                    Black
                                                                    ({dropCityDetails && dropCityDetails.race_black}%),
                                                                    Asian
                                                                    ({dropCityDetails && dropCityDetails.race_asian}%),
                                                                    Other
                                                                    ({dropCityDetails && dropCityDetails.race_other}%),
                                                                    Multiple
                                                                    ({dropCityDetails && dropCityDetails.race_multiple}%)</p>

                                                                <dl className="row">
                                                                    <dd className="col-sm-4">{dropCityDetails && dropCityDetails.male} (%
                                                                        Male)
                                                                    </dd>
                                                                    <dd className="col-sm-8">{dropCityDetails && dropCityDetails.femalePercentage} (%
                                                                        Female (%))
                                                                    </dd>
                                                                </dl>

                                                                <dl className="row">
                                                                    <dd className="col-sm-4">{dropCityDetails && dropCityDetails.married} (%
                                                                        Married)
                                                                    </dd>
                                                                    <dd className="col-sm-8">{dropCityDetails && dropCityDetails.familySize} (Family
                                                                        Size.)
                                                                    </dd>
                                                                </dl>

                                                            </dd>

                                                            <dt className="col-sm-3">Financial Data</dt>
                                                            <dd className="col-sm-9">

                                                                <p>${dropCityDetails && dropCityDetails.homeValue} (Avg.Home
                                                                    Value)</p>
                                                                <p>${dropCityDetails && dropCityDetails.rent_median} (Avg.Home
                                                                    Median Rent)</p>
                                                                <p>${dropCityDetails && dropCityDetails.householdIncomeMedian} (Median
                                                                    Income)</p>
                                                                <p>{dropCityDetails && dropCityDetails.incomeHouseHoldSixFigure} (
                                                                    House holds with 6 figure income (%))</p>
                                                                <p>{dropCityDetails && dropCityDetails.homeOwnership} (%
                                                                    Home Ownership)</p>

                                                                <p>{dropCityDetails && dropCityDetails.educationCollegeOrAbove} (%
                                                                    Education College or Above)</p>

                                                                <p>{dropCityDetails && dropCityDetails.laborForcePArticipation} (%
                                                                    Labor Force Participation)</p>


                                                            </dd>

                                                            <dt className="col-sm-3">Fips</dt>
                                                            <dd className="col-sm-9">
                                                                <p>{dropCityDetails && dropCityDetails.countyFips}</p>
                                                                <p>{dropCityDetails && dropCityDetails.countyFipsAll} (Fips
                                                                    All)</p>
                                                            </dd>
                                                            {/*<dt className="col-sm-3">Zips</dt>*/}
                                                            {/*<dd className="col-sm-9">*/}
                                                            {/*    <p className="small_zips">{dropCityDetails && dropCityDetails.zips} </p>*/}
                                                            {/*</dd>*/}


                                                        </dl>


                                                        {/*End of pick city details from us city data*/}
                                                    </div>
                                                </div>
                                            </div>

                                            <hr/>
                                            <button className="btn-success pop-close-button"
                                                    onClick={closeModal}>Close
                                            </button>
                                            {/*<button onClick={closeModal}>_close</button>*/}

                                        </Modal>
                                    </div>
                                </td>

                                {/*<td>*/}
                                {/*    <div className="heart-button">*/}
                                {/*        <button onClick={handleOpen}><i className="bi bi-justify"></i></button>*/}
                                {/*        {open ? (*/}
                                {/*            <ul className="menu">*/}
                                {/*                <li className="menu-item">*/}
                                {/*                    <button onClick={(e) => {*/}
                                {/*                        moveRecord(dash_item.row_id, 'down')*/}
                                {/*                    }} id={dash_item.row_id}>Move Down</button>*/}
                                {/*                </li>*/}
                                {/*                <li className="menu-item">*/}
                                {/*                    <button onClick={(e) => {*/}
                                {/*                        moveRecord(dash_item.row_id, 'up')*/}
                                {/*                    }} id={dash_item.row_id}>Move Up</button>*/}
                                {/*                </li>*/}
                                {/*            </ul>*/}
                                {/*        ) : null}*/}
                                {/*        /!*{open ? <div>Is Open</div> : <div>Is Closed</div>}*!/*/}
                                {/*    </div>*/}
                                {/*</td>*/}
                            </tr>))}
                        <tr>
                        </tr>
                        </tbody>
                    </Table>
                    <br/><br/><br/><br/>

                </div>)}

        </div>

    );
};

export default withAuthenticationRequired(RateTable, {
    onRedirecting: () => <PageLoader/>,
});
