import {API_BASE_URL} from "../common/ApiUrl";
import axios from "axios";


class UserService {

    createSubscription(planType, type, email, user, token, plan, coupon, serverToken) {
        return axios.post(`${API_BASE_URL}payments/subscriptions/create-subscription`, {
            planType: planType,
            type: type,
            email: email,
            id: user.sub,
            token: token,
            plan: plan,
            coupon: coupon
        }, {headers: {Authorization: `Bearer ${serverToken}`}})
    }

    cancelSubscription(id, serverToken) {
        return axios.post(API_BASE_URL + 'payments/subscriptions/' + "cancel-subscription", {
            subscriptionId: id
        }, {headers: {Authorization: `Bearer ${serverToken}`}})
    }

    upload(file, serverToken) {
        let formData = new FormData();
        formData.append("file", file);
        return axios.post(API_BASE_URL + 'upload', {
            file
        }, {headers: {
            Authorization: `Bearer ${serverToken}`,
            "Content-Type": "multipart/form-data",
        }})
    }

    doGeneralDataBigSearch(startday, endday, source, token) {
            return axios.get(`${API_BASE_URL}search/general_lane_data?start_date=${startday}&end_date=${endday}&source=${source}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    citySearch(id, token) {
        return axios.get(`${API_BASE_URL}cities?id=${id}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    cityWeatherSearch(location, token) {
        return axios.get(`${API_BASE_URL}dashboard-weather?location=${location}&days=5`, {headers: {Authorization: `Bearer ${token}`}});

    }

    cityFuelSearch(location, token) {
        return axios.get(`${API_BASE_URL}dashboard-fuel?id=${location}`, {headers: {Authorization: `Bearer ${token}`}});
    }




    laneAnalytics(startdate, endday, token) {
        return axios.get(`${API_BASE_URL}search/lane_analytics?end_date=${endday}&start_date=${startdate}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    getUserSubscription(token, user) {
        return axios.get(API_BASE_URL + `payments/subscriptions` , {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    getTicker(token, user, ticker) {
        let tickerUrl = '';
        if (ticker === 1) {
            tickerUrl = 'one'
        } else {
            tickerUrl = 'two'
        }
        return axios.get(API_BASE_URL + 'ticker/' + tickerUrl , {headers: {Authorization: `Bearer ${token}`}});
    }

    getWeatherAndFuelTicker(pickCityId,
                            pickCity,
                            pickState,
                            dropCityId,
                            dropCity,
                            dropState,
                            token) {
        return axios.post(`${API_BASE_URL}ticker/weather-and-fuel`, {
            pickCityId: pickCityId,
            pickCity: pickCity,
            pickState: pickState,
            dropCityId: dropCityId,
            dropCity: dropCity,
            dropState: dropState
        }, {headers: {Authorization: `Bearer ${token}`}})
    }

    me(token, user) {
        return axios.get(API_BASE_URL + 'user' , {headers: {Authorization: `Bearer ${token}`}});
    }

    config(token, user) {
        return axios.get(API_BASE_URL + 'config' , {headers: {Authorization: `Bearer ${token}`}});
    }

    userSettingsFlow(token, user) {
        return axios.get(API_BASE_URL + 'user' + '/settings-flow' , {headers: {Authorization: `Bearer ${token}`}});
    }

    userSubscriptionStatus(token, user) {
        return axios.get(API_BASE_URL + 'user' + '/subscription-status' , {headers: {Authorization: `Bearer ${token}`}});
    }

    userRedisSession(token, user) {
        return axios.get(API_BASE_URL + 'user' + '/redis-session' , {headers: {Authorization: `Bearer ${token}`}});
    }

    userRedisSessionRemove(token) {
        return axios.get(API_BASE_URL + 'user' + '/clear-session' , {headers: {Authorization: `Bearer ${token}`}});
    }

    userSubscriptionStatusPair(token, user) {
        return axios.get(API_BASE_URL + 'user' + '/subscription-status-pair' , {headers: {Authorization: `Bearer ${token}`}});
    }

    mySubscription(token, user) {
        return axios.get(API_BASE_URL +  'mysubscription' , {headers: {Authorization: `Bearer ${token}`}});
    }

    verify(user, token) {
        return axios.post(API_BASE_URL + 'verify', {
            sub: user.sub,
            email: user.email,
            given_name: user.given_name,
            locale: user.locale,
            picture: user.picture,
            name: user.name,
            nickname: user.nickname,
            family_name: user.family_name
        }, {headers: {Authorization: `Bearer ${token}`}});
    }

    getLaneData(token, user) {
        return axios.get(API_BASE_URL +  'lane-data', {headers: {Authorization: `Bearer ${token}`}});
    }

    user(token) {
        return axios.get(API_BASE_URL + `user`,);
    }

    putUserMCALEI(payload, token) {
        return axios.put(API_BASE_URL + `user-update-mc-alei`, {
            sub: payload.sub,
            motorCarrierNumber: payload.motorCarrierNumber,
            dotNumber: payload.dotNumber,
            aleiNumber: payload.aleiNumber,
            smartPrefixField: payload.smartPrefixField,
            subPrefixField: payload.subPrefixField,
            dnbField: payload.dnbField,
        }, {headers: {Authorization: `Bearer ${token}`}});
    }

    putUserTickersAndPairs(payload, token) {
        return axios.put(API_BASE_URL + `user-pairs-update`, {
            enable_assesorials: payload.enable_assesorials,
            sub: payload.sub,
            pairs: payload.pairs,
            tickerOne: payload.tickerOne,
            tickerTwo: payload.tickerTwo,
            tickerOneName: payload.tickerOneName,
            tickerTwoName: payload.tickerTwoName
        }, {headers: {Authorization: `Bearer ${token}`}});
    }

    updateConfig(payload, token) {
        console.log('RED');
        console.log(payload);
        return axios.put(API_BASE_URL + `config/update`, payload, {headers: {Authorization: `Bearer ${token}`}});
    }

    doProductionDataIngestion(days, miles, lessThanLineHaulAmount, greaterThanLineHaulAmount, token) {
        http://localhost:8080/api/lane_data/index/production_lane_data?number_of_days_from_today=31&total_miles=100&line_haul_amount_greater_than=100&line_haul_amount_less_than=30000
            return axios.get(API_BASE_URL + `index/production_lane_data?number_of_days_from_today=${days}&total_miles=${miles}&line_haul_amount_greater_than=${greaterThanLineHaulAmount}&line_haul_amount_less_than=${lessThanLineHaulAmount}`, {headers: {Authorization: `Bearer ${token}`}});
    }

    putUser(payload, token) {
        return axios.put(API_BASE_URL + `user-update`, {
            firstName: payload.firstName,
            lastName: payload.lastName,
            mobileNumber: payload.mobileNumber,
            addressLineOne: payload.addressLineOne,
            addressLineTwo: payload.addressLineTwo,
            state: payload.state,
            city: payload.city,
            country: payload.country,
            zipCode: payload.zipCode,
            sub: payload.sub,
            howDoYouKnowUs: payload.howDoYouKnowUs,
        }, {headers: {Authorization: `Bearer ${token}`}});
    }

}

export default new UserService();