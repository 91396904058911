import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LoginButton } from "../../buttons/login-button";
import { LogoutButton } from "../../buttons/logout-button";
import { SignupButton } from "../../buttons/signup-button";
import {SignupButtonMobile} from "../../buttons/signup-button-mobile";
import {LoginButtonMobile} from "../../buttons/login-button-mobile";
import {LogoutButtonMobile} from "../../buttons/logout-button-mobile";

export const MobileNavBarButtons = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__buttons">
      {!isAuthenticated && (
        <>
          <SignupButtonMobile />
          <LoginButtonMobile />
        </>
      )}
      {isAuthenticated && (
        <>
          <LogoutButtonMobile />
        </>
      )}
    </div>
  );
};
