import React, {useEffect, useState} from "react";
import StripeCheckout from "react-stripe-checkout";
import {loadStripe} from "@stripe/stripe-js";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import LaneService from "../services/LaneService";
import UserService from "../services/UserService";
import Progress from "./Progress";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import CompanyLogo from "../assets/farelanes-logo.png-modified.png"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useNavigate } from "react-router-dom";



//do something...


import SILVERTOP from "../assets/images/pricing/farelanes-silver-sales-top.png";
import GOLDTOP from "../assets/images/pricing/farelanes-gold-sales-top.png";
import PLATINUMTOP from "../assets/images/pricing/farelanes-platinum-sales-top.png";
import COMINGSOON from "../assets/images/pricing/coming-soon-diag.png";
import SUBSCRIBE_MONTHLY from "../assets/images/pricing/yellow-subscribe-monthly.png";
import SUBSCRIBE_ANNUALLY from "../assets/images/pricing/yellow-subscribe-annually.png";
import CANCEL_SUBSCRIPTION from "../assets/images/pricing/Green-cancel-subscription.png";
import FarelanesLogo from "../assets/images/get-b-2.png";
import {GOLD_YEARLY_API_KEY, PLATINUM_MONTHLY_API_KEY, PLATINUM_YEARLY_API_KEY} from "../common/ApiUrl";
import {GOLD_MONTHLY_API_KEY} from "../common/ApiUrl";
import {SILVER_YEARLY_API_KEY} from "../common/ApiUrl";
import {SILVER_MONTHLY_API_KEY} from "../common/ApiUrl";
import {STRIPE_KEY} from "../common/ApiUrl";
import {SignupButtonOne} from "./buttons/signup-button-one";
import {LoginButton} from "./buttons/login-button";
import {ProfileComponent} from "../pages/Profile";
import {initAmplitude, sendAmplitudeData, setAmplitudeUserId} from "../common/amplitude";

function TransactionProgress() {
    return null;
}

export const SubscriptionsComponent = () => {

    let navigate = useNavigate();

    let initialValues = {
        silverCouponCode: '',
        goldCouponCode: '',
        platinumCouponCode: ''
    }

    const [quote, setQuote] = useState(initialValues)

    const onChange = (e) => {
        setQuote({...quote, [e.target.name]: e.target.value});
    };

    const [state, setState] = useState({
        allSubs: undefined,
        sub: undefined,
        noSub: false,
        isLoaded: false,
        data: undefined,
        content: "",
        showResult: false,
        apiMessage: undefined,
        error: null,
        columns: [
            {
                name: 'Subscription',
                selector: row => row.created,
                sortable: true,
                cell: row => <div className="subscription-details-font">{row.subscriptionType}</div>
            },
            {
                name: 'Created On',
                selector: row => row.created,
                sortable: true,
                cell: row => <div
                    className="subscription-details-font">{dayjs.unix(row.created).format("MM-DD-YYYY HH:mm:ss A")}</div>
            },
            {
                name: 'Start Date',
                selector: row => row.currentPeriodStart,
                sortable: true,
                cell: row => <div
                    className="subscription-details-font">{dayjs.unix(row.currentPeriodStart).format("MM-DD-YYYY HH:mm:ss A")}</div>
            },

            {
                name: 'End Date',
                selector: row => row.currentPeriodEnd,
                sortable: true,
                // cell: row => <div
                //     className="subscription-details-font">{dayjs.unix(row.currentPeriodEnd).format("MM-DD-YYYY HH:mm:ss A")}</div>

                cell: row => <div className="subscription-details-font">{row.status &&
                    <span className="alert-success">{row.planType} - {row.subscriptionType}</span>}{!row.status &&
                    <span className="">{dayjs.unix(row.currentPeriodEnd).format("MM-DD-YYYY HH:mm:ss A")}</span>}</div>
            },

            {
                name: 'Cancellation Date',
                selector: row => row.cancellationDate,
                sortable: true,
                cell: row => <div
                    className="subscription-details-font">{row.cancellationDate && dayjs(row.cancellationDate).format("MM-DD-YYYY HH:mm:ss A")}</div>
            },

            {
                id: 'latestInvoice',
                name: 'Status',
                selector: row => row.status,
                sortable: true,
                cell: row => <div className="subscription-details-font">{row.status &&
                    <span className="alert-success">Active</span>}{!row.status &&
                    <span className="alert-danger">Cancelled</span>}</div>
            }
        ]
    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();

            UserService.getUserSubscription(token, user).then(
                response => {
                    state.showResult = true;
                    state.data = response.allSubSctiptions;
                    state.sub = response;
                    response.allSubSctiptions.sort((a, b) => parseFloat(b.created) - parseFloat(a.created));
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        sub: response,
                        data: response.allSubSctiptions
                    });

                    if (!response.status) {
                        state.sub = 'M';
                        state.noSub = true;
                        setState({
                            ...state,
                            sub: 'M',
                            noSub: true,
                        });
                    }
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    useEffect(() => {
        callApi();
    }, []);

    async function handleSilverToken(token) {
        toast("Subscription creation started, please wait ....");
        const serverToken = await getAccessTokenSilently();
        UserService.createSubscription('Monthly Recurring', 'Silver', user.email, user, token.id, SILVER_MONTHLY_API_KEY, quote.silverCouponCode, serverToken).then(
            response => {
                toast("Silver Subscription create success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Created", {
                    'subscription_type': 'SILVER MONTHLY',
                    'email': user.email,
                    'coupon_code': quote.silverCouponCode,
                    'token': token.id
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false)
                    navigate("/dashboard",{state:{refresh:true}});
                });
            },
            error => {
                toast("Invalid Coupon CODE " + quote.silverCouponCode, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        )
    }

    async function handleSilverAnnualToken(token) {
        toast("Subscription creation started, please wait ....");
        const serverToken = await getAccessTokenSilently();
        UserService.createSubscription('Yearly Recurring', 'Silver', user.email, user, token.id, SILVER_YEARLY_API_KEY, quote.silverCouponCode, serverToken).then(
            response => {
                toast("Silver Yearly Subscription create success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Created", {
                    'subscription_type': 'SILVER YEARLY',
                    'email': user.email,
                    'coupon_code': quote.silverCouponCode,
                    'token': token.id
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false);
                    navigate("/dashboard",{state:{refresh:true}});
                });
            },
            error => {
                toast("Invalid Coupon CODE" + quote.silverCouponCode, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        )
    }

    async function handleGoldToken(token) {
        toast("Subscription creation started, please wait ....");
        const serverToken = await getAccessTokenSilently();
        UserService.createSubscription('Monthly Recurring', 'Gold', user.email, user, token.id, GOLD_MONTHLY_API_KEY, quote.goldCouponCode, serverToken).then(
            response => {
                toast("Gold Subscription create success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Created", {
                    'subscription_type': 'GOLD MONTHLY',
                    'email': user.email,
                    'coupon_code': quote.goldCouponCode,
                    'token': token.id
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false)
                    navigate("/dashboard",{state:{refresh:true}});
                });

            },
            error => {
                toast("Invalid Coupon CODE " + quote.goldCouponCode, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        )
    }

    async function handleGoldYearlyToken(token) {


        toast("Subscription creation started, please wait ....");
        const serverToken = await getAccessTokenSilently();
        UserService.createSubscription('Yearly Recurring', 'Gold', user.email, user, token.id, GOLD_YEARLY_API_KEY, quote.goldCouponCode, serverToken).then(
            response => {
                toast("Gold Yearly Subscription create success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Created", {
                    'subscription_type': 'GOLD YEARLY',
                    'email': user.email,
                    'coupon_code': quote.goldCouponCode,
                    'token': token.id
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false)
                    navigate("/dashboard",{state:{refresh:true}});
                });
            },
            error => {
                toast("Invalid Coupon CODE " + quote.goldCouponCode, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        )
    }


    async function handlePlatinumToken(token) {
        toast("Platinum Subscription creation started, please wait ....");
        const serverToken = await getAccessTokenSilently();
        UserService.createSubscription('Monthly Recurring', 'Platinum', user.email, user, token.id, PLATINUM_MONTHLY_API_KEY, quote.platinumCouponCode, serverToken).then(
            response => {
                toast("Platinum Subscription create success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Created", {
                    'subscription_type': 'Platinum MONTHLY',
                    'email': user.email,
                    'coupon_code': quote.platinumCouponCode,
                    'token': token.id
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false)
                    navigate("/dashboard",{state:{refresh:true}});
                });

            },
            error => {
                toast("Invalid Coupon CODE " + quote.platinumCouponCode, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        )
    }

    async function handlePlatinumYearlyToken(token) {


        toast("Platinum Subscription creation started, please wait ....");
        const serverToken = await getAccessTokenSilently();
        UserService.createSubscription('Yearly Recurring', 'Platinum', user.email, user, token.id, PLATINUM_YEARLY_API_KEY, quote.platinumCouponCode, serverToken).then(
            response => {
                toast("Platinum Yearly Subscription create success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Created", {
                    'subscription_type': 'PLATINUM YEARLY',
                    'email': user.email,
                    'coupon_code': quote.platinumCouponCode,
                    'token': token.id
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false)
                    navigate("/dashboard",{state:{refresh:true}});
                });
            },
            error => {
                toast("Invalid Coupon CODE " + quote.platinumCouponCode, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        )
    }

    async function cancelSubscription(e, id) {
        state.showResult = false;
        state.noSub = true;

        const serverToken = await getAccessTokenSilently();
        UserService.cancelSubscription(id, serverToken).then(
            response => {
                toast("Subscription cancel success");
                state.showResult = true;
                //Add analytics
                initAmplitude();
                setAmplitudeUserId(user.email);
                sendAmplitudeData("Subscription Cancelled", {
                    'email': user.email
                });
                //End Analytics
                callApi().then(r => {
                    window.location.reload(false);
                    navigate("/dashboard",{state:{refresh:true}});
                });
            },
            error => {

            }
        )
    }

    return (
        <>
            <div className="form-group ">
                <div>

                    {/*<ToastContainer />*/}

                    <ToastContainer
                        className= "toast-message"
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    {/* Same as */}
                    {!state.showResult &&
                        <div>
                            <Progress></Progress>
                        </div>
                    }


                    {state.showResult && (
                        <div>
                            <section id="pricing" className="pricing-content section-padding">
                                <div className="container-fluid">
                                    <div className="row text-center">
                                        <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp "
                                             data-wow-duration="1s"
                                             data-wow-delay="0.1s" data-wow-offset="0">
                                            <div className="pricing_design">
                                                <div className="single-pricing">
                                                    <div className="fcuk">
                                                    <div className="price-head">
                                                        {/*<h2>Silver</h2>*/}
                                                        <img className="silver-top" src={SILVERTOP}/>
                                                        <div><h1>$49.95</h1><span className="subscription-per-month">per month</span>
                                                            <p className="two-months-free lef-align-line-height">or get
                                                                two months FREE</p>
                                                            <p className="two-months-free lef-align-line-height">when
                                                                you pay $499.95/year!</p>
                                                        </div>
                                                    </div>
                                                    <div className="left-align-text">Search</div>
                                                    <div className="left-align-text-after lef-align-line-height">City
                                                        Pair
                                                        Line Haul Rates
                                                    </div>
                                                    <div className="left-align-text-after lef-align-line-height">All
                                                        Trailer Types & Power
                                                        Only
                                                    </div>

                                                    <div className="left-align-text">Personalize</div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Favorite
                                                        Lanes
                                                    </div>
                                                    <div className="left-align-text-after lef-align-line-height">City
                                                        Pair
                                                        Rate Tickers
                                                    </div>


                                                    <div className="left-align-text">Top Paying <span><small>Lanes with rates always available</small></span>
                                                    </div>

                                                    <div className="left-align-text">ALL NEW Features include</div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Programmable
                                                        Desktop with memory
                                                    </div>
                                                    <div className="left-align-text-after lef-align-line-height">Scopes
                                                        from setup - favorite city pairs
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Unlimited
                                                        desktop city pairs
                                                    </div>
                                                    <div className="left-align-text">AI for managing Text, <span><small>Reporting, and other new GOLD and PLATINUM TASKS</small></span>
                                                    </div>
                                                    </div>
                                                    {/*<small>Pricing Dashboard</small>*/}
                                                    {state.sub && state.sub.status && state.sub.subscriptionType == 'Silver' && (
                                                        <div>
                                                            <strong className="green">Subscribed !!</strong><br/>
                                                            <button className=""
                                                                    onClick={(e) => {
                                                                        cancelSubscription(e, state.sub.subscriptionId);
                                                                    }}
                                                            >
                                                                <img className="silver-top" src={CANCEL_SUBSCRIPTION}/>
                                                            </button>
                                                        </div>)}
                                                    {/*<br/>*/}
                                                    {/*<br/>*/}
                                                    {/*<br/>*/}

                                                    {!state.sub || state.sub == 'M' && (<div className="coupon-code">

                                                        <p className="left-align-text">Coupon Code</p>
                                                        <input type="text"
                                                               value={quote.silverCouponCode || ""}
                                                               name="silverCouponCode"
                                                               placeholder="        COUPON CODE"
                                                               onChange={onChange}/>
                                                        <br/><br/>

                                                    </div>)}

                                                    {!state.sub || state.sub == 'M' && (<div>


                                                        <div className="btn-group a01">

                                                            <StripeCheckout
                                                                label="SUBSCRIBE MONTHLY"
                                                                image={CompanyLogo}
                                                                name="Farelanes LLC."
                                                                alipay={true}
                                                                description="Farelanes Silver Subscription."
                                                                token={handleSilverToken}
                                                                email={user.email}
                                                                stripeKey={STRIPE_KEY}
                                                            >
                                                                {/*<button className="">*/}
                                                                {/*    SUBSCRIBE MONTHLY*/}

                                                                {/*</button>*/}

                                                                <div className="">
                                                                    <button type="button"
                                                                            className="button__logout more_style_price_buttons">
                                                                        SUBSCRIBE MONTHLY
                                                                    </button>
                                                                </div>

                                                            </StripeCheckout>

                                                            <StripeCheckout
                                                                label="SUBSCRIBE YEARLY"
                                                                image={CompanyLogo}
                                                                name="Farelanes LLC."
                                                                alipay={true}
                                                                description="Farelanes Silver Subscription - Annual."
                                                                token={handleSilverAnnualToken}
                                                                email={user.email}
                                                                stripeKey={STRIPE_KEY}
                                                            >
                                                                <div className="col-xs">
                                                                    <button type="button"
                                                                            className="button__logout more_style_price_buttons">
                                                                        SUBSCRIBE ANNUALLY
                                                                    </button>
                                                                </div>

                                                            </StripeCheckout>

                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>

                                        </div>


                                        <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp  "
                                             data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                            <div className="pricing_design">
                                                <div className="single-pricing">
                                                    <div className="fcuk">
                                                    <div className="price-head">
                                                        <div className="price-head">
                                                            {/*<h2>Silver</h2>*/}
                                                            <img className="silver-top" src={GOLDTOP}/>
                                                            <div><h1>$99.95</h1><span
                                                                className="subscription-per-month">per month</span>

                                                                <p className="two-months-free lef-align-line-height">or
                                                                    get two months FREE</p>
                                                                <p className="two-months-free lef-align-line-height">when
                                                                    you pay $999.95/year!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="left-align-text"><span className="text-underline">All Silver Subscription Plus & NEW  Features</span>
                                                    </div>

                                                    <div
                                                        className="left-align-text-after lef-align-line-height">*Request
                                                        for Quote (RFQ) via Spreadsheet
                                                    </div>
                                                    <div className="left-align-text">*Reporting
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Load/Price
                                                        analytics by equipment, Date(s)
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Last 6 months lane analytics, export
                                                    </div>
                                                    <div className="left-align-text">Hinting in your Favorite Lanes(now
                                                        optionable)
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Tool Tip
                                                        hinting reveals assessorial details
                                                    </div>


                                                    <div className="left-align-text">City Pair Loading with level 2
                                                        detail
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height"> Level 2
                                                        load optics enable (pick /
                                                        drop zone, load analysis, lane rates vs.
                                                        linehaul, Fuel surcharge, all assessorial
                                                        fees optics by load)

                                                    </div>

                                                    <div className="left-align-text">Export level 2 data for additional
                                                        analysis
                                                        and reporting

                                                    </div>

                                                    <div className="left-align-text">*Realtime - Petroleum by lane –
                                                        city pair
                                                        Weather by lane – city pair
                                                        *AI for managing Text, Reporting, and other new
                                                        GOLD and PLATINUM TASKS


                                                    </div>
                                                    </div>

                                                    {/*<p>* New or updated FEATURES</p>*/}
                                                    {state.sub && state.sub.status && state.sub.subscriptionType == 'Gold' && (
                                                        <div>
                                                            <strong className="green">Subscribed !!</strong><br/>
                                                            <button className=""
                                                                    onClick={(e) => {
                                                                        cancelSubscription(e, state.sub.subscriptionId);
                                                                    }}
                                                            >
                                                                <img className="silver-top" src={CANCEL_SUBSCRIPTION}/>
                                                            </button>
                                                        </div>)}
                                                    {!state.sub || state.sub == 'M' && (<div>
                                                        <p className="left-align-text">Coupon Code</p>
                                                        <input type="text"
                                                               value={quote.goldCouponCode || ""}
                                                               name="goldCouponCode"
                                                               placeholder="        COUPON CODE"
                                                               onChange={onChange}/>

                                                        <div>
                                                            <div className="btn-group a02">

                                                                <StripeCheckout
                                                                    image={CompanyLogo}
                                                                    name="Farelanes LLC."
                                                                    alipay={true}
                                                                    description="Farelanes Gold Subscription."
                                                                    token={handleGoldToken}
                                                                    email={user.email}
                                                                    stripeKey="pk_test_51NXRnlLgudBKYy4G62j8q4gr1zLOTkqsGZQg3w8TzLueTT0Dt0JjJkXekDXdqsntFWLXjv2QnyF39QYpsW3FSGqC00VqfUNp6g"
                                                                >
                                                                    <div className="col-xs">
                                                                        <button type="button"
                                                                                className="button__logout more_style_price_buttons">
                                                                            SUBSCRIBE MONTHLY
                                                                        </button>
                                                                    </div>

                                                                </StripeCheckout>

                                                                <StripeCheckout
                                                                    label="SUBSCRIBE YEARLY"
                                                                    image={CompanyLogo}
                                                                    name="Farelanes LLC."
                                                                    alipay={true}
                                                                    description="Farelanes Gold Subscription - Annual."
                                                                    token={handleGoldYearlyToken}
                                                                    email={user.email}
                                                                    stripeKey={STRIPE_KEY}
                                                                >
                                                                    <div className="col-xs">
                                                                        <button type="button"
                                                                                className="button__logout more_style_price_buttons">
                                                                            SUBSCRIBE ANNUALLY
                                                                        </button>
                                                                    </div>

                                                                </StripeCheckout>
                                                            </div>
                                                        </div>
                                                    </div>)}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp "
                                             data-wow-duration="1s"
                                             data-wow-delay="0.1s" data-wow-offset="0">
                                            <div className="pricing_design">
                                                <div className="single-pricing">
                                                    <div className="fcuk">
                                                    <div className="price-head">
                                                        {/*<h2>Silver</h2>*/}
                                                        <img className="silver-top" src={PLATINUMTOP}/>
                                                        <div><h1>$199.95</h1><span className="subscription-per-month">per month</span>
                                                            <p className="two-months-free lef-align-line-height">or get
                                                                two months FREE</p>
                                                            <p className="two-months-free lef-align-line-height">when
                                                                you pay $1999.95/year!</p>
                                                        </div>
                                                    </div>
                                                    <div className="left-align-text"><span className="text-underline">All GOLD Subscription Plus & NEW  Features</span>
                                                    </div>

                                                    <div
                                                        className="left-align-text-after lef-align-line-height">*Enhanced
                                                        Request for Quote (RFQ) via Spreadsheet
                                                    </div>
                                                    <div className="left-align-text">Hinting in your Favorite Lanes (now
                                                        optionable)
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height">Tool Tip
                                                        hinting reveals assessorial details
                                                    </div>

                                                    <div className="left-align-text">City Pair Loading with level 2
                                                        detail
                                                    </div>
                                                    <div
                                                        className="left-align-text-after lef-align-line-height"> Level 2
                                                        load optics enable (pick /
                                                        drop zone, load analysis, lane rates vs.
                                                        linehaul, Fuel surcharge, all assessorial
                                                        fees optics by load)

                                                    </div>

                                                    <div className="left-align-text">Export level 2 data for additional
                                                        analysis
                                                        and reporting

                                                    </div>

                                                    <div className="left-align-text">*Realtime - Petroleum by lane –
                                                        city pair
                                                        Weather by lane – city pair
                                                    </div>
                                                    <div className="left-align-text">*AI for converting images to Text,
                                                        Reporting, and Insights
                                                    </div>

                                                    <div className="left-align-text">*New Insights Reporting Tab with
                                                        top 6
                                                        equipment types and Market Indexes
                                                    </div>

                                                    <div
                                                        className="left-align-text-after lef-align-line-height"> Load/Price
                                                        analytics by equipment, Date(s)

                                                    </div>


                                                    <div
                                                        className="left-align-text-after lef-align-line-height"> 1 Year
                                                        lane
                                                        history analytics, reporting / export

                                                    </div>
                                                    </div>
                                                    {/*<small>Pricing Dashboard</small>*/}
                                                    {state.sub && state.sub.status && state.sub.subscriptionType == 'Platinum' && (
                                                        <div>
                                                            <strong className="green">Subscribed !!</strong><br/>
                                                            <button className=""
                                                                    onClick={(e) => {
                                                                        cancelSubscription(e, state.sub.subscriptionId);
                                                                    }}
                                                            >
                                                                <img className="silver-top" src={CANCEL_SUBSCRIPTION}/>
                                                            </button>
                                                        </div>)}
                                                    {/*<br/>*/}
                                                    {/*<br/>*/}
                                                    {/*<br/>*/}

                                                    {!state.sub || state.sub == 'M' && (<div className="coupon-code">

                                                        <p className="left-align-text">Coupon Code</p>
                                                        <input type="text"
                                                               value={quote.platinumCouponCode || ""}
                                                               name="platinumCouponCode"
                                                               placeholder="        COUPON CODE"
                                                               onChange={onChange}/>
                                                        <br/><br/>

                                                    </div>)}
                                                    {!state.sub || state.sub == 'M' && (<div>


                                                        <div className="btn-group a01">

                                                            <StripeCheckout
                                                                label="SUBSCRIBE MONTHLY"
                                                                image={CompanyLogo}
                                                                name="Farelanes LLC."
                                                                alipay={true}
                                                                description="Farelanes Platinum Subscription."
                                                                token={handlePlatinumToken}
                                                                email={user.email}
                                                                stripeKey={STRIPE_KEY}
                                                            >
                                                                {/*<button className="">*/}
                                                                {/*    SUBSCRIBE MONTHLY*/}

                                                                {/*</button>*/}

                                                                <div className="">
                                                                    <button type="button"
                                                                            className="button__logout more_style_price_buttons">
                                                                        SUBSCRIBE MONTHLY
                                                                    </button>
                                                                </div>

                                                            </StripeCheckout>

                                                            <StripeCheckout
                                                                label="SUBSCRIBE YEARLY"
                                                                image={CompanyLogo}
                                                                name="Farelanes LLC."
                                                                alipay={true}
                                                                description="Farelanes Silver Subscription - Annual."
                                                                token={handlePlatinumYearlyToken}
                                                                email={user.email}
                                                                stripeKey={STRIPE_KEY}
                                                            >
                                                                <div className="col-xs">
                                                                    <button type="button"
                                                                            className="button__logout more_style_price_buttons">
                                                                        SUBSCRIBE ANNUALLY
                                                                    </button>
                                                                </div>

                                                            </StripeCheckout>

                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                    {(<div className="subscription-history-margin">

                                        <hr/>
                                        <div className="align-content-center"><h4>&nbsp;&nbsp;Subscription History</h4>
                                        </div>
                                        <header className="subscription-details-font">
                                            <DataTable className="subscription-details-font"
                                                // dense={true}
                                                       striped={true}
                                                       columns={state.columns}
                                                       data={state.data}
                                                // defaultSortFieldId="lane"
                                                       defaultSortAsc={false}
                                                       direction="auto"
                                                       fixedHeaderScrollHeight="300px"
                                                       pagination
                                                       responsive
                                                       subHeaderAlign="right"
                                                       subHeaderWrap
                                            />
                                        </header>
                                        <br/><br/><br/>
                                    </div>)}
                                </div>
                            </section>
                        </div>)}

                </div>
            </div>
        </>
    );
};

export default withAuthenticationRequired(SubscriptionsComponent, {
    onRedirecting: () => <Loading/>,
});
