import React, {useEffect, useState} from "react";
import {LaneData} from "../components/LaneData";
import {PageLayout} from "../components/page-layout";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {TopLanes} from "../components/TopLanes";
import Profile from "./Profile";
import {RateTable} from "./RateTable";
import {CitySearch} from "../components/CitySearch";
import {Favorites} from "./Favorites";
import {Insights} from "./Insights";
import FileUploadProcessor from "./lane-data-file-upload";
import Administration from "./Administration";

// import TwitterLogo from "../assets/images/social/icons8-twitter.gif";
// import CompanyLogo from "../assets/images/farelanes-logo-gold.png";
import RightDashboard from "./RightDashboard";
import UserService from "../services/UserService";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";


const Dashboard = () => {
    let navigate = useNavigate();
    const adminUsers = ['brad.kozak@farelanes.com', 'casey.larkin@farelanes.com', 'andrew.cohen@farelanes.com', 'neal.huffman@farelanes.com', 'kapil.reddy@farelanes.com'];

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    let [subscriptionType, setSubscriptionType] = useState("Default");
    let [defaultIndex, setDefaultIndex] = useState(0);
    let [apiLoaded, setApiLoaded] = useState(false);
    const callApi = async () => {
        console.log('DASH TABS CALL');
        const token = await getAccessTokenSilently();
        UserService.verify(user, token).then(
            response => {
                console.log('Subscription type i==s ' + response.data.subscrÏiptionType);
                setSubscriptionType(response.data.subscriptionType);

                if (response.data.subscriptionType == 'Default') {
                    setDefaultIndex(6);
                }
                if (response.data.subscriptionType == 'Silver') {
                    setDefaultIndex(0);
                }

                if (response.data.subscriptionType == 'Gold') {
                    setDefaultIndex(0);
                }

                if (response.data.subscriptionType == 'Platinum') {
                    setDefaultIndex(0);
                }
                setApiLoaded(true);

            },
            error => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log('Call Api() finished'));
    }, [defaultIndex]);

    return (
        <div>
            <PageLayout>
                {apiLoaded && (
                <div className="">
                    <div className={"row"}>
                        <div className={"col-md-9 v2-dashboard-vertical-divider"}>
                            <LaneData></LaneData>
                            <Tabs defaultIndex={defaultIndex}>
                                <TabList className="profile-root-tabs">
                                    <Tab disabled={subscriptionType === 'Default'}>Dashboard</Tab>
                                    <Tab>Top Rates</Tab>
                                    <Tab disabled={subscriptionType === 'Default'}>Rates & History</Tab>
                                    <Tab
                                        disabled={subscriptionType === 'Default' || subscriptionType === 'Silver'}><span
                                        type="button" className="" data-toggle="tooltip"
                                        data-placement="top" title="Gold & Platinum Feature">
                                    Favorite Lanes
                                </span></Tab>
                                    <Tab
                                        disabled={subscriptionType === 'Default' || subscriptionType === 'Silver' || subscriptionType === 'Gold'}>      <span
                                        type="button" className="" data-toggle="tooltip"
                                        data-placement="top" title="Platinum Feature">
                                    Insights
                                </span></Tab>
                                    <Tab
                                        disabled={subscriptionType === 'Default' || subscriptionType == 'Silver'}><span
                                        type="button" className="" data-toggle="tooltip"
                                        data-placement="top" title="Gold & Platinum Feature">
                                    RFQ
                                </span></Tab>
                                    <Tab>Setup</Tab>
                                    {isAuthenticated && ((adminUsers.indexOf(user.email) > -1)) && (
                                        <Tab disabled={subscriptionType === 'Default'}>Administration</Tab>
                                    )}
                                </TabList>
                                <TabPanel><RateTable></RateTable></TabPanel>
                                <TabPanel className={"v2-tab-content"}> <TopLanes></TopLanes></TabPanel>
                                <TabPanel><CitySearch></CitySearch></TabPanel>
                                <TabPanel><Favorites></Favorites></TabPanel>
                                <TabPanel><Insights></Insights></TabPanel>
                                <TabPanel><FileUploadProcessor></FileUploadProcessor></TabPanel>
                                <TabPanel><Profile></Profile></TabPanel>
                                {isAuthenticated && ((adminUsers.indexOf(user.email) > -1)) && (
                                    <TabPanel><Administration></Administration></TabPanel>
                                )}
                            </Tabs>
                        </div>

                        <div className={"col-md-3"}>
                            <RightDashboard></RightDashboard>
                        </div>
                    </div>
                </div>
                )}

            </PageLayout>

        </div>
    );
}

export default Dashboard;
