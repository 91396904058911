import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import LaneService from "../services/LaneService";
import Select from "react-select";

import 'react-tooltip/dist/react-tooltip.css'
import AsyncSelect from "react-select/async";
import UserService from "../services/UserService";
import {toast, ToastContainer} from "react-toastify";
import {PageLoader} from "./page-loader";
import {Table} from "react-bootstrap";
import {abbrRegion, customStyles, equipmentTypeStyles, radiusStyles} from "../pages/Constants";
import {useNavigate} from "react-router-dom";
import Toggle from 'react-toggle'
import "react-toggle/style.css"

export const TickersAndPairs = () => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
    });

    let navigate = useNavigate();

    const [farelanesUser, setFarelanesUser] = useState(null);
    let [assesorialsIsEnabled, setAssesorialsIsEnabled] = useState(null);
    let [subscription, setSubscription] = useState(null);

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        farelanesUser: {firstName: 'Frank', lastName: 'Murphy'}
                    });
                    console.log('assesorialsIsEnabled');
                    console.log(response.data.enable_assesorials);
                    setAssesorialsIsEnabled(response.data.enable_assesorials);
                    setFarelanesUser({
                        pairs: response.data.pairs,
                        tickerOne: response.data.tickerOne,
                        tickerTwo: response.data.tickerTwo,
                        assesorialsIsEnabled: response.data.enable_assesorials
                        // tickerOneName: response.data.tickerOneName,
                        // tickerTwoName: response.data.tickerTwoName
                    });

                    setAssesorialsIsEnabled(response.data.enable_assesorials);
                    setSubscription(response.data.subscription);
                    setTickerOneName(response.data.tickerOneName);
                    setTickerTwoName(response.data.tickerTwoName);

                    if (response.data.pairs) {


                        setSelectedPickCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].start);
                        setSelectedDropCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].end);
                        setDayRangeValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].days);
                        setEquipmentValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].equipment);
                        setRadiusValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].miles);
                        setDropRadiusValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].dropmiles);

                        setSelectedPickCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].start);
                        setSelectedDropCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].end);
                        setDayRangeValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].days);
                        setEquipmentValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].equipment);
                        setRadiusValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].miles);
                        setDropRadiusValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].dropmiles);

                        setSelectedPickCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].start);
                        setSelectedDropCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].end);
                        setDayRangeValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].days);
                        setEquipmentValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].equipment);
                        setRadiusValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].miles);
                        setDropRadiusValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].dropmiles);


                        setSelectedPickCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].start);
                        setSelectedDropCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].end);
                        setDayRangeValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].days);
                        setEquipmentValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].equipment);
                        setRadiusValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].miles);
                        setDropRadiusValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].dropmiles);

                        setSelectedPickCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].start);
                        setSelectedDropCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].end);
                        setDayRangeValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].days);
                        setEquipmentValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].equipment);
                        setRadiusValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].miles);
                        setDropRadiusValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].dropmiles);

                        setSelectedPickCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].start);
                        setSelectedDropCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].end);
                        setDayRangeValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].days);
                        setEquipmentValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].equipment);
                        setRadiusValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.pairs[2].miles);
                        setDropRadiusValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.pairs[2].dropmiles);


                        setSelectedPickCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].start);
                        setSelectedDropCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].end);
                        setDayRangeValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].days);
                        setEquipmentValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].equipment);
                        setRadiusValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].miles);
                        setDropRadiusValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].dropmiles);

                        setSelectedPickCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].start);
                        setSelectedDropCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].end);
                        setDayRangeValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].days);
                        setEquipmentValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].equipment);
                        setRadiusValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].miles);
                        setDropRadiusValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].dropmiles);

                        setSelectedPickCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].start);
                        setSelectedDropCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].end);
                        setDayRangeValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].days);
                        setEquipmentValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].equipment);
                        setRadiusValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.pairs[2].miles);
                        setDropRadiusValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.pairs[2].dropmiles);


                    }
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log(''));
    }, []);


    const equipmentOptions = [
        {value: 'Auto Carrier', label: 'Auto Carrier'},
        {value: 'Container', label: 'Container'},
        {value: 'Conestoga', label: 'Conestoga'},
        {value: 'Dry Van', label: 'Dry Van'},
        {value: 'Flatbed', label: 'Flatbed'},
        {value: 'Hopper Bottom', label: 'Hopper Bottom'},
        {value: 'Lowboy', label: 'Lowboy'},
        {value: 'Double Drop', label: 'Double Drop'},
        {value: 'Maxi', label: 'Maxi'},
        {value: 'Power Only', label: 'Power Only'},
        {value: 'Pneumatic', label: 'Pneumatic'},
        {value: 'Reefer', label: 'Reefer'},
        {value: 'Straight Box', label: 'Straight Box'},
        {value: 'Step Deck', label: 'Step Deck'},
        {value: 'Sprinter', label: 'Sprinter'},
        {value: 'Tanker', label: 'Tanker'},
        {value: 'Truck and Trailer', label: 'Truck & Trailer'},
    ]

    const [tickerOneName, setTickerOneName] = useState('');
    const [tickerTwoName, setTickerTwoName] = useState('');

    const [inputPickCityValue, setPickCityValue] = useState('');
    const [inputDropCityValue, setDropCityValue] = useState('');


    const [inputPickCityValue2, setPickCityValue2] = useState('');
    const [inputDropCityValue2, setDropCityValue2] = useState('');

    const [inputPickCityValue3, setPickCityValue3] = useState('');
    const [inputDropCityValue3, setDropCityValue3] = useState('');

    const [inputPickCityValue4, setPickCityValue4] = useState('');
    const [inputDropCityValue4, setDropCityValue4] = useState('');

    const [inputPickCityValue5, setPickCityValue5] = useState('');
    const [inputDropCityValue5, setDropCityValue5] = useState('');

    const [inputPickCityValue6, setPickCityValue6] = useState('');
    const [inputDropCityValue6, setDropCityValue6] = useState('');


    const [inputPickCityValue7, setPickCityValue7] = useState('');
    const [inputDropCityValue7, setDropCityValue7] = useState('');

    const [inputPickCityValue8, setPickCityValue8] = useState('');
    const [inputDropCityValue8, setDropCityValue8] = useState('');

    const [inputPickCityValue9, setPickCityValue9] = useState('');
    const [inputDropCityValue9, setDropCityValue9] = useState('');


    const [selectedPickCityValue, setSelectedPickCityValue] = useState(null);
    const [selectedDropCityValue, setSelectedDropCityValue] = useState(null);
    const [equipmentValue, setEquipmentValue] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue, setRadiusValue] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue, setDayRangeValue] = useState({value: 15, label: '15 days'});


    const [selectedPickCityValue2, setSelectedPickCityValue2] = useState(null);
    const [selectedDropCityValue2, setSelectedDropCityValue2] = useState(null);
    const [equipmentValue2, setEquipmentValue2] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue2, setRadiusValue2] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue2, setDayRangeValue2] = useState({value: 15, label: '15 days'});


    const [selectedPickCityValue3, setSelectedPickCityValue3] = useState(null);
    const [selectedDropCityValue3, setSelectedDropCityValue3] = useState(null);
    const [equipmentValue3, setEquipmentValue3] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue3, setRadiusValue3] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue3, setDayRangeValue3] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue4, setSelectedPickCityValue4] = useState(null);
    const [selectedDropCityValue4, setSelectedDropCityValue4] = useState(null);
    const [equipmentValue4, setEquipmentValue4] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue4, setRadiusValue4] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue4, setDayRangeValue4] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue5, setSelectedPickCityValue5] = useState(null);
    const [selectedDropCityValue5, setSelectedDropCityValue5] = useState(null);
    const [equipmentValue5, setEquipmentValue5] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue5, setRadiusValue5] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue5, setDayRangeValue5] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue6, setSelectedPickCityValue6] = useState(null);
    const [selectedDropCityValue6, setSelectedDropCityValue6] = useState(null);
    const [equipmentValue6, setEquipmentValue6] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue6, setRadiusValue6] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue6, setDayRangeValue6] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue7, setSelectedPickCityValue7] = useState(null);
    const [selectedDropCityValue7, setSelectedDropCityValue7] = useState(null);
    const [equipmentValue7, setEquipmentValue7] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue7, setRadiusValue7] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue7, setDayRangeValue7] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue8, setSelectedPickCityValue8] = useState(null);
    const [selectedDropCityValue8, setSelectedDropCityValue8] = useState(null);
    const [equipmentValue8, setEquipmentValue8] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue8, setRadiusValue8] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue8, setDayRangeValue8] = useState({value: 15, label: '15 days'});

    const [selectedPickCityValue9, setSelectedPickCityValue9] = useState(null);
    const [selectedDropCityValue9, setSelectedDropCityValue9] = useState(null);
    const [equipmentValue9, setEquipmentValue9] = useState({value: 'Dry Van', label: 'Dry Van'});
    const [radiusValue9, setRadiusValue9] = useState({value: 200, label: '200 miles'});
    const [dayRangeValue9, setDayRangeValue9] = useState({value: 15, label: '15 days'});


    const [dropRadiusValue, setDropRadiusValue] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue2, setDropRadiusValue2] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue3, setDropRadiusValue3] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue4, setDropRadiusValue4] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue5, setDropRadiusValue5] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue6, setDropRadiusValue6] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue7, setDropRadiusValue7] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue8, setDropRadiusValue8] = useState({value: 200, label: '200 miles'});
    const [dropRadiusValue9, setDropRadiusValue9] = useState({value: 200, label: '200 miles'});


    const handleDropRadiusChange = (event) => {
        setDropRadiusValue(event);
    };

    const handleDropRadiusChange2 = (event) => {
        setDropRadiusValue2(event);
    };

    const handleDropRadiusChange3 = (event) => {
        setDropRadiusValue3(event);
    };

    const handleDropRadiusChange4 = (event) => {
        setDropRadiusValue4(event);
    };

    const handleDropRadiusChange5 = (event) => {
        setDropRadiusValue5(event);
    };


    const handleDropRadiusChange6 = (event) => {
        setDropRadiusValue6(event);
    };


    const handleDropRadiusChange7 = (event) => {
        setDropRadiusValue7(event);
    };

    const handleDropRadiusChange8 = (event) => {
        setDropRadiusValue8(event);
    };

    const handleDropRadiusChange9 = (event) => {
        setDropRadiusValue9(event);
    };


    const radiusOptions = [
        {value: 50, label: '50 miles'},
        {value: 100, label: '100 miles'},
        {value: 150, label: '150 miles'},
        {value: 200, label: '200 miles'}
    ]

    const dayRangeOptions = [
        {value: 15, label: '15 days'},
        {value: 7, label: '7 days'},
        {value: 3, label: '3 days'},
    ];


    const onChangeTickerOneName = (event) => {
        setTickerOneName(event.target.value);
    };

    const onChangeTickerTwoName = (event) => {
        setTickerTwoName(event.target.value);
    };
    // handle input change event ONE
    const handleEquipmentChange = (event) => {
        setEquipmentValue(event);
    };

    const handleRadiusChange = (event) => {
        setRadiusValue(event);
    };

    const handleDayRangeValueChange = value => {
        setDayRangeValue(value);
    };
    const handlePickCityInputChange = value => {
        setPickCityValue(value);
    };

    // handle selection
    const handlePickCityChange = value => {
        setSelectedPickCityValue(value);
    }

    const handleDropCityInputChange = value => {
        setDropCityValue(value);
    };

    // handle selection
    const handleDropCityChange = value => {
        setSelectedDropCityValue(value);
    }

    //// handle input change event ONE END


    //TWO

    const handleEquipmentChange2 = (event) => {
        setEquipmentValue2(event);
    };

    const handleRadiusChange2 = (event) => {
        setRadiusValue2(event);
    };

    const handleDayRangeValueChange2 = value => {
        setDayRangeValue2(value);
    };
    const handlePickCityInputChange2 = value => {
        setPickCityValue2(value);
    };

    // handle selection
    const handlePickCityChange2 = value => {
        setSelectedPickCityValue2(value);
    }

    const handleDropCityInputChange2 = value => {
        setDropCityValue2(value);
    };

    // handle selection
    const handleDropCityChange2 = value => {
        setSelectedDropCityValue2(value);
    }

    //TWO end


    //THREE

    const handleEquipmentChange3 = (event) => {
        setEquipmentValue3(event);
    };

    const handleRadiusChange3 = (event) => {
        setRadiusValue3(event);
    };

    const handleDayRangeValueChange3 = value => {
        setDayRangeValue3(value);
    };
    const handlePickCityInputChange3 = value => {
        setPickCityValue3(value);
    };

    // handle selection
    const handlePickCityChange3 = value => {
        setSelectedPickCityValue3(value);
    }

    const handleDropCityInputChange3 = value => {
        setDropCityValue3(value);
    };

    // handle selection
    const handleDropCityChange3 = value => {
        setSelectedDropCityValue3(value);
    }

    //THREE end

    //FOUR

    const handleEquipmentChange4 = (event) => {
        setEquipmentValue4(event);
    };

    const handleRadiusChange4 = (event) => {
        setRadiusValue4(event);
    };

    const handleDayRangeValueChange4 = value => {
        setDayRangeValue4(value);
    };
    const handlePickCityInputChange4 = value => {
        setPickCityValue4(value);
    };

    // handle selection
    const handlePickCityChange4 = value => {
        setSelectedPickCityValue4(value);
    }

    const handleDropCityInputChange4 = value => {
        setDropCityValue4(value);
    };

    // handle selection
    const handleDropCityChange4 = value => {
        setSelectedDropCityValue4(value);
    }

    //FOUR end

    //FIVE

    const handleEquipmentChange5 = (event) => {
        setEquipmentValue5(event);
    };

    const handleRadiusChange5 = (event) => {
        setRadiusValue5(event);
    };

    const handleDayRangeValueChange5 = value => {
        setDayRangeValue5(value);
    };
    const handlePickCityInputChange5 = value => {
        setPickCityValue5(value);
    };

    // handle selection
    const handlePickCityChange5 = value => {
        setSelectedPickCityValue5(value);
    }

    const handleDropCityInputChange5 = value => {
        setDropCityValue5(value);
    };

    // handle selection
    const handleDropCityChange5 = value => {
        setSelectedDropCityValue5(value);
    }

    //FIVE end

    //SIX

    const handleEquipmentChange6 = (event) => {
        setEquipmentValue6(event);
    };

    const handleRadiusChange6 = (event) => {
        setRadiusValue6(event);
    };

    const handleDayRangeValueChange6 = value => {
        setDayRangeValue6(value);
    };
    const handlePickCityInputChange6 = value => {
        setPickCityValue6(value);
    };

    // handle selection
    const handlePickCityChange6 = value => {
        setSelectedPickCityValue6(value);
    }

    const handleDropCityInputChange6 = value => {
        setDropCityValue6(value);
    };

    // handle selection
    const handleDropCityChange6 = value => {
        setSelectedDropCityValue6(value);
    }

    //FISIXVE end

    //SEVEN

    const handleEquipmentChange7 = (event) => {
        setEquipmentValue7(event);
    };

    const handleRadiusChange7 = (event) => {
        setRadiusValue7(event);
    };

    const handleDayRangeValueChange7 = value => {
        setDayRangeValue7(value);
    };
    const handlePickCityInputChange7 = value => {
        setPickCityValue7(value);
    };

    // handle selection
    const handlePickCityChange7 = value => {
        setSelectedPickCityValue7(value);
    }

    const handleDropCityInputChange7 = value => {
        setDropCityValue7(value);
    };

    // handle selection
    const handleDropCityChange7 = value => {
        setSelectedDropCityValue7(value);
    }

    //SEVEN end

    //EIGHT

    const handleEquipmentChange8 = (event) => {
        setEquipmentValue8(event);
    };

    const handleRadiusChange8 = (event) => {
        setRadiusValue8(event);
    };

    const handleDayRangeValueChange8 = value => {
        setDayRangeValue8(value);
    };
    const handlePickCityInputChange8 = value => {
        setPickCityValue8(value);
    };

    // handle selection
    const handlePickCityChange8 = value => {
        setSelectedPickCityValue8(value);
    }

    const handleDropCityInputChange8 = value => {
        setDropCityValue8(value);
    };

    // handle selection
    const handleDropCityChange8 = value => {
        setSelectedDropCityValue8(value);
    }

    //EIGHT end



    const handleCheeseChange = value => {
        // setSelectedDropCityValue8(value);
        let blah = assesorialsIsEnabled;
        console.log(value);
        setAssesorialsIsEnabled(!blah);
        // console.log(assesorialsIsEnabled);
    }

    //NINE

    const handleEquipmentChange9 = (event) => {
        setEquipmentValue9(event);
    };

    const handleRadiusChange9 = (event) => {
        setRadiusValue9(event);
    };

    const handleDayRangeValueChange9 = value => {
        setDayRangeValue9(value);
    };
    const handlePickCityInputChange9 = value => {
        setPickCityValue9(value);
    };

    // handle selection
    const handlePickCityChange9 = value => {
        setSelectedPickCityValue9(value);
    }

    const handleDropCityInputChange9 = value => {
        setDropCityValue9(value);
    };

    // handle selection
    const handleDropCityChange9 = value => {
        setSelectedDropCityValue9(value);
    }

    //NINE end


    // load options using API call
    const loadOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getCityOptions(inputValue, token).then(
            response => {
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    };

    async function save_pairs() {
        let payload = {};

        payload.enable_assesorials = assesorialsIsEnabled;
        payload.sub = user.sub;
        payload.tickerOneName = tickerOneName;
        payload.tickerTwoName = tickerTwoName;
        payload.pairs = [
            {

                start: selectedPickCityValue,
                end: selectedDropCityValue,
                days: dayRangeValue,
                equipment: equipmentValue,
                miles: radiusValue,
                dropmiles: dropRadiusValue,
            },
            {
                start: selectedPickCityValue2,
                end: selectedDropCityValue2,
                days: dayRangeValue2,
                equipment: equipmentValue2,
                miles: radiusValue2,
                dropmiles: dropRadiusValue2
            },
            {
                start: selectedPickCityValue3,
                end: selectedDropCityValue3,
                days: dayRangeValue3,
                equipment: equipmentValue3,
                miles: radiusValue3,
                dropmiles: dropRadiusValue3
            }
        ];

        payload.tickerOne = [
            {
                start: selectedPickCityValue4,
                end: selectedDropCityValue4,
                days: dayRangeValue4,
                equipment: equipmentValue4,
                miles: radiusValue4,
                dropmiles: dropRadiusValue4
            },
            {
                start: selectedPickCityValue5,
                end: selectedDropCityValue5,
                days: dayRangeValue5,
                equipment: equipmentValue5,
                miles: radiusValue5,
                dropmiles: dropRadiusValue5
            },
            {
                start: selectedPickCityValue6,
                end: selectedDropCityValue6,
                days: dayRangeValue6,
                equipment: equipmentValue6,
                miles: radiusValue6,
                dropmiles: dropRadiusValue6
            }
        ];


        payload.tickerTwo = [
            {
                start: selectedPickCityValue7,
                end: selectedDropCityValue7,
                days: dayRangeValue7,
                equipment: equipmentValue7,
                miles: radiusValue7,
                dropmiles: dropRadiusValue7
            },
            {
                start: selectedPickCityValue8,
                end: selectedDropCityValue8,
                days: dayRangeValue8,
                equipment: equipmentValue8,
                miles: radiusValue8,
                dropmiles: dropRadiusValue8
            },
            {
                start: selectedPickCityValue9,
                end: selectedDropCityValue9,
                days: dayRangeValue9,
                equipment: equipmentValue9,
                miles: radiusValue9,
                dropmiles: dropRadiusValue9
            }
        ];
        const token = await getAccessTokenSilently();
        UserService.putUserTickersAndPairs(payload, token).then(
            response => {
                setState({
                    ...state,
                    showResult: true,
                    apiMessage: response.data,
                    farelanesUser: {firstName: 'Frank', lastName: 'Murphy'}
                });
                setFarelanesUser({
                    pairs: response.data.pairs,
                });
                if (response.data.pairs) {


                    setSelectedPickCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].start);
                    setSelectedDropCityValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].end);
                    setDayRangeValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].days);
                    setEquipmentValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].equipment);
                    setRadiusValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].miles);
                    setDropRadiusValue(response.data.pairs && response.data.pairs[0] && response.data.pairs[0].dropmiles);

                    setSelectedPickCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].start);
                    setSelectedDropCityValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].end);
                    setDayRangeValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].days);
                    setEquipmentValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].equipment);
                    setRadiusValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].miles);
                    setDropRadiusValue2(response.data.pairs && response.data.pairs[1] && response.data.pairs[1].dropmiles);

                    setSelectedPickCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].start);
                    setSelectedDropCityValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].end);
                    setDayRangeValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].days);
                    setEquipmentValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].equipment);
                    setRadiusValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].miles);
                    setDropRadiusValue3(response.data.pairs && response.data.pairs[2] && response.data.pairs[2].dropmiles);


                    setSelectedPickCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].start);
                    setSelectedDropCityValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].end);
                    setDayRangeValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].days);
                    setEquipmentValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].equipment);
                    setRadiusValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].miles);
                    setDropRadiusValue4(response.data.tickerOne && response.data.tickerOne[0] && response.data.tickerOne[0].dropmiles);

                    setSelectedPickCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].start);
                    setSelectedDropCityValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].end);
                    setDayRangeValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].days);
                    setEquipmentValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].equipment);
                    setRadiusValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].miles);
                    setDropRadiusValue5(response.data.tickerOne && response.data.tickerOne[1] && response.data.tickerOne[1].dropmiles);

                    setSelectedPickCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].start);
                    setSelectedDropCityValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].end);
                    setDayRangeValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].days);
                    setEquipmentValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].equipment);
                    setRadiusValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].miles);
                    setDropRadiusValue6(response.data.tickerOne && response.data.tickerOne[2] && response.data.tickerOne[2].dropmiles);


                    setSelectedPickCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].start);
                    setSelectedDropCityValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].end);
                    setDayRangeValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].days);
                    setEquipmentValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].equipment);
                    setRadiusValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].miles);
                    setDropRadiusValue7(response.data.tickerTwo && response.data.tickerTwo[0] && response.data.tickerTwo[0].dropmiles);

                    setSelectedPickCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].start);
                    setSelectedDropCityValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].end);
                    setDayRangeValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].days);
                    setEquipmentValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].equipment);
                    setRadiusValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].miles);
                    setDropRadiusValue8(response.data.tickerTwo && response.data.tickerTwo[1] && response.data.tickerTwo[1].dropmiles);

                    setSelectedPickCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].start);
                    setSelectedDropCityValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].end);
                    setDayRangeValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].days);
                    setEquipmentValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].equipment);
                    setRadiusValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].miles);
                    setDropRadiusValue9(response.data.tickerTwo && response.data.tickerTwo[2] && response.data.tickerTwo[2].dropmiles);
                }
                toast("Pairs and Tickers updated successfully.");
                window.location.reload(false)
                navigate("/dashboard",{state:{refresh:true}});
            },
            error => {

            }
        )
    }

    return (
        <>
            <div className="form-group">
                <ToastContainer
                    className= "toast-message"
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    />
                <div>

                    <div>
                        <h3 className="bi-globe-asia-australia"> Global Configuration</h3>
                        <hr/>

                        {subscription != 'Silver' && (<div>
                        <h5>Assesorials On/Off</h5>
                        <Toggle
                            id='cheese-status'
                            checked={assesorialsIsEnabled}
                            name="Reddy"
                            className="toggle-adjust"
                            // defaultChecked={assesorialsIsEnabled}
                            onChange={handleCheeseChange}/>
                        <hr/>
                        </div>)}
                        {/*<legend className="the-legend font-weight-bold">Your TOP 3 Routes.</legend>*/}
                        <h5>Your TOP 3 routes</h5>
                        <div className="form-row dashboard-text">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Equipment Type</th>
                                    <th>Pick City</th>
                                    <th>Radius/miles</th>
                                    <th>Drop City</th>
                                    <th>Radius/miles</th>
                                    <th>Days</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <Select
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue}
                                            styles={equipmentTypeStyles}
                                            onChange={handleEquipmentChange}
                                        />
                                    </td>
                                    <td>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            styles={customStyles}
                                            value={selectedPickCityValue}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange}
                                            onChange={handlePickCityChange}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue}
                                            styles={radiusStyles}
                                            onChange={handleRadiusChange}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange}
                                            onChange={handleDropCityChange}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue}
                                            styles={radiusStyles}
                                            onChange={handleDropRadiusChange}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue}
                                            styles={radiusStyles}
                                            onChange={handleDayRangeValueChange}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue2}
                                            onChange={handleEquipmentChange2}
                                        />
                                    </td>
                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue2}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange2}
                                            onChange={handlePickCityChange2}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue2}
                                            onChange={handleRadiusChange2}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue2}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange2}
                                            onChange={handleDropCityChange2}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue2}
                                            onChange={handleDropRadiusChange2}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={dayRangeOptions}
                                            value={dayRangeValue2}
                                            onChange={handleDayRangeValueChange2}
                                        />
                                    </td>
                                </tr>

                                {/*Top 3 Routes*/}
                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue3}
                                            onChange={handleEquipmentChange3}
                                        />
                                    </td>
                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue3}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange3}
                                            onChange={handlePickCityChange3}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue2}
                                            onChange={handleRadiusChange2}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue3}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange3}
                                            onChange={handleDropCityChange3}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue3}
                                            onChange={handleDropRadiusChange3}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue3}
                                            onChange={handleDayRangeValueChange3}
                                        />
                                    </td>
                                </tr>
                                {/*End Top Routes*/}

                                <h6>Ticker 1
                                    - {tickerOneName}</h6>


                                <input type="text"
                                       name="tockerOneName"
                                       value={tickerOneName}
                                       onChange={onChangeTickerOneName}
                                       className="form-control text-uppercase"
                                       placeholder="Ticker 1"
                                ></input>
                                <br/>
                                <tr>
                                    <td>
                                        <Select
                                            label="What equipment"
                                            styles={equipmentTypeStyles}
                                            options={equipmentOptions}
                                            value={equipmentValue4}
                                            onChange={handleEquipmentChange4}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue4}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange4}
                                            onChange={handlePickCityChange4}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue4}
                                            onChange={handleRadiusChange4}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue4}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange4}
                                            onChange={handleDropCityChange4}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue4}
                                            onChange={handleDropRadiusChange4}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue4}
                                            onChange={handleDayRangeValueChange4}
                                        />
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue5}
                                            onChange={handleEquipmentChange5}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue5}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange5}
                                            onChange={handlePickCityChange5}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue5}
                                            onChange={handleRadiusChange5}
                                        />
                                    </td>
                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue5}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange5}
                                            onChange={handleDropCityChange5}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue5}
                                            onChange={handleDropRadiusChange5}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue5}
                                            onChange={handleDayRangeValueChange5}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue6}
                                            onChange={handleEquipmentChange6}
                                        />
                                    </td>
                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue6}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange6}
                                            onChange={handlePickCityChange6}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue6}
                                            onChange={handleRadiusChange6}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue6}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange6}
                                            onChange={handleDropCityChange6}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue6}
                                            onChange={handleDropRadiusChange6}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={dayRangeOptions}
                                            value={dayRangeValue6}
                                            onChange={handleDayRangeValueChange6}
                                        />
                                    </td>
                                </tr>

                                <h6>Ticker 2
                                    - {tickerTwoName}</h6>

                                <input type="text"
                                       name="tickerTwoName"
                                       value={tickerTwoName}
                                       onChange={onChangeTickerTwoName}
                                       className="form-control text-uppercase"
                                       placeholder="Ticker 2"
                                ></input>
                                <br/>

                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue7}
                                            onChange={handleEquipmentChange7}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue7}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange7}
                                            onChange={handlePickCityChange7}
                                        />
                                    </td>


                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue7}
                                            onChange={handleRadiusChange7}
                                        />
                                    </td>


                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue7}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange7}
                                            onChange={handleDropCityChange7}
                                        />
                                    </td>


                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue7}
                                            onChange={handleDropRadiusChange7}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue7}
                                            onChange={handleDayRangeValueChange7}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue8}
                                            onChange={handleEquipmentChange8}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue8}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange8}
                                            onChange={handlePickCityChange8}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue8}
                                            onChange={handleRadiusChange8}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue8}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange8}
                                            onChange={handleDropCityChange8}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue8}
                                            onChange={handleDropRadiusChange8}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue8}
                                            onChange={handleDayRangeValueChange8}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <Select
                                            styles={equipmentTypeStyles}
                                            label="What equipment"
                                            options={equipmentOptions}
                                            value={equipmentValue9}
                                            onChange={handleEquipmentChange9}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedPickCityValue9}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handlePickCityInputChange9}
                                            onChange={handlePickCityChange9}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={radiusValue9}
                                            onChange={handleRadiusChange9}
                                        />
                                    </td>

                                    <td>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={selectedDropCityValue9}
                                            getOptionLabel={e => e.city + ', ' + e.stateName}
                                            getOptionValue={e => e.city}
                                            loadOptions={loadOptions}
                                            onInputChange={handleDropCityInputChange9}
                                            onChange={handleDropCityChange9}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What radius"
                                            options={radiusOptions}
                                            value={dropRadiusValue9}
                                            onChange={handleDropRadiusChange9}
                                        />
                                    </td>

                                    <td>
                                        <Select
                                            styles={radiusStyles}
                                            label="What days"
                                            options={dayRangeOptions}
                                            value={dayRangeValue9}
                                            onChange={handleDayRangeValueChange9}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-12">
                            <div className="mt-4">
                                <input className="button__settings-save" type="submit"
                                       onClick={save_pairs}
                                       value="Save"/>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </>
    );
};

export default withAuthenticationRequired(TickersAndPairs, {
    onRedirecting: () => <PageLoader/>,
});

