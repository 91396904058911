import React from "react";

const Loading = () => (
  <div className="spinner">

      <div className="text-center p-3">
          <div className="text-center p-3">
              <div className="spinner-border secondary text-secondary" role="status">
                  <span className="sr-only">Loading...</span>
              </div>
          </div>

      </div>
    {/*<img src={loading} alt="Loading" />*/}
  </div>
);

export default Loading;
